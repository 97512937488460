// Version : 1.0
<template>
  <div id="app">
    <div class="mainApp">
      <Navbar />
      <router-view></router-view>
    </div>
    <notifications-list />
  </div>
</template>

<style scoped>
.mainApp {
  transition: all 0.2s ease;
  z-index: 1;
}
</style>

<script>
import Navbar from './components/Titan/t-navbar.vue'
import NotificationsList from './components/Titan/t-notification-list.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    NotificationsList
  }
}
</script>
