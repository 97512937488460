<template>
  <div class="main">
    <Main_Navbar />
    <Main />
    <Footer />
  </div>
</template>

<script>
import Main_Navbar from "../components/Titan/t-main-navbar.vue";
import Main from "../components/Titan/t-main.vue";
import Footer from "../components/Titan/t-footer.vue";

export default {
  components: {
    Main_Navbar,
    Main,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: absolute;
  width: 100%;
  // background: #051a20;
}
</style>
