<template>
  <div class="page-single-product" id="main" v-if="product.length !== 0">
    <Title
      title_id="imgTitle-page-shop"
      :title_text="product.name[selectLanguage()]"
      subtitle_text=""
    />

    <!-- Product -->
    <section class="module-small">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 mb-sm-40 singlePage-imagebox">
            <a class="gallery">
              <img :src="selectedImg" alt="Single Product Image" />
            </a>
            <ul class="product-gallery">
              <li
                v-for="(childImage, index) in product.children_image"
                :key="index"
              >
                <img
                  :src="require('@/assets' + childImage.image)"
                  alt="children image"
                  @click="selectImage(index)"
                />
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <div class="price font-alt">
                  <span class="amount">{{
                    product.name[selectLanguage()]
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row mb-20">
              <div class="col-sm-12">
                <div class="pt-20">
                  <h4 class="product-title font-alt-title">
                    {{ product.title[selectLanguage()] }}
                  </h4>
                </div>
              </div>
            </div>
            <div class="row mb-20">
              <div class="col-sm-12">
                <div class="description tab-pane">
                  <span style="white-space: pre-line;">
                    {{ product.subtitle[selectLanguage()] }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row mb-20">
              <!-- <div class="col-sm-4 mb-sm-20">
                <input
                  v-model.number="quantitiy"
                  type="number"
                  class="form-control input-lg"
                  min="1"
                  max="20"
                />
              </div>
              <div class="col-sm-8">
                <button
                  class="btn btn-lg btn-block btn-round btn-b"
                  @click="addToCart()"
                  v-b-tooltip.hover
                  :title="product.cost + ' sum'"
                >
                  {{ $t("addtocart") }}
                </button>
              </div> -->

              <!-- New Ver with Share button -->
              <!-- <div class="col-sm-4 mb-sm-20 pt-2 pb-2">
                <b-form-spinbutton
                  id="demo-sb"
                  v-model="quantitiy"
                  min="1"
                  max="100"
                ></b-form-spinbutton>
              </div> -->

              <!-- ADD TO CART BUTTON -->
              <div class="col-sm-6 pt-2 pb-2">
                <el-button
                  icon="el-icon-shopping-cart-2"
                  @click="addToCart()"
                  style="width: 100%"
                >
                  {{ $t("addtocart") }}
                </el-button>
              </div>
              <!-- SHARE BUTTON -->
              <div class="col-sm-6 pt-2 pb-2 ">
                <el-button
                  icon="el-icon-share"
                  type="primary"
                  plain
                  @click="shareProduct()"
                >
                   {{ $t("share") }} 
                </el-button>
              </div>
            </div>
            <div class="row mb-20">
              <div class="col-sm-12">
                <div class="product_meta">
                  {{ product.view[selectLanguage()] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-70">
          <div class="col-sm-12">
            <!-- Category -->
            <ul class="nav nav-tabs font-alt" role="tablist">
              <li
                v-for="menuCategory in categories"
                :key="menuCategory.value"
                :class="{ active: isActive(menuCategory.name) }"
                @click.prevent="setActive(menuCategory.name)"
              >
                <a :href="menuCategory.id">
                  <span :class="menuCategory.icon"></span>
                  {{ menuCategory.title }}</a
                >
              </li>
            </ul>

            <!-- Information Table -->
            <div class="tab-content">
              <!-- Indications for Use -->
              <div
                class="tab-pane"
                :class="{ 'active show': isActive('indication') }"
                id="description"
                role="tabpanel"
              >
                <!-- <p>Indications for Use</p> -->
                <ul>
                  <li
                    v-for="(item, index) in product.indication[selectLanguage()]
                      .data"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>

              <!-- Composition -->
              <div
                class="tab-pane"
                :class="{ 'active show': isActive('composition') }"
                id="data-sheet"
                role="tabpanel"
              >
                <div>
                  <!-- <span>В одной капсуле:</span>
                  <span>В 10 мл сиропа</span> -->
                  <b-table
                    striped
                    hover
                    :items="product.composition[selectLanguage()].items"
                  >
                    <template #cell(Экстракт)="data">
                      <span v-html="data.value"></span>
                    </template>
                  </b-table>
                </div>
              </div>

              <!-- Way of Using -->
              <div
                class="tab-pane"
                :class="{ 'active show': isActive('using') }"
                id="reviews"
                role="tabpanel"
              >
                <ul class="">
                  <li
                    v-for="(item, index) in product.useway[selectLanguage()]
                      .data"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>

                <hr />
                <b>{{ $t("singlePage.contraindications") }}</b>

                <ul class="">
                  <li
                    v-for="(item, index) in product.useway[selectLanguage()]
                      .contraindications"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr class="divider-w" />

    <!-- Related Products -->
    <section class="module-small">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-sm-offset-3">
            <h2 class="module-title font-alt">
              {{ $t("singlePage.related") }}
            </h2>
          </div>
        </div>
        <div class="row multi-columns-row">
          <ProductCard
            v-for="(item, index) in products"
            :key="index"
            :product="item"
          />
        </div>
      </div>
    </section>
    <t-footer />

    <el-dialog  center :visible.sync="dialogShareVisible" width="550px">
      <div class="container text-center" center>
        <h5>{{ $t("share") }} </h5>
        <b-row>
          <b-col lg="12" class="pt-2 pb-2 d-flex justify-center align-center text-center">
            <el-button icon @click="dialogShareVisible = false">
              <telegram :url="product_Url" scale="2"></telegram>
            </el-button>
            <el-button icon @click="dialogShareVisible = false">
              <facebook :url="product_Url" scale="2"></facebook>
            </el-button>

            <el-button icon @click="dialogShareVisible = false">
              <twitter :url="product_Url" scale="2"></twitter>
            </el-button>
            <el-button icon @click="dialogShareVisible = false">
              <whats-app :url="product_Url" scale="2"></whats-app>
            </el-button>
            <el-button icon @click="dialogShareVisible = false">
              <google :url="product_Url" scale="2"></google>
            </el-button>
            <el-button icon @click="dialogShareVisible = false">
              <email :url="product_Url" scale="2"></email>
            </el-button>
          </b-col>
          <b-col lg="12">
            <b-row>
              <b-col lg="8">
                <el-input
                  placeholder="Please input"
                  v-model="product_Url"
                  width="100%"
                ></el-input>
              </b-col>
              <b-col lg="4">
                <el-button  style="width:100%;" @click="copyURL">Copy Link</el-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

    </el-dialog>
  </div>
</template>

<style lang="scss" scoped src="@/css/Main_style.scss"></style>

<script>
import { mapActions, mapGetters } from "vuex";
import Title from "../components/widgets/w-titleImagebar.vue";
import ProductCard from "../components/Titan/t-shop-card.vue";

import tFooter from "../components/Titan/t-footer.vue";
import {
  Telegram,
  Facebook,
  Twitter,
  WhatsApp,
  Email,
  Google,
} from "vue-socialmedia-share";

export default {
  props: ["router_name"],

  data() {
    return {
      categories: [
        {
          name: "indication",
          value: "indication",
          title: this.$t("singlePage.indication"),
          id: "#description",
          icon: "",
        },
        {
          name: "composition",
          value: "composition",
          title: this.$t("singlePage.composition"),
          id: "#data-sheet",
          icon: "",
        },
        {
          name: "using",
          value: "using",
          title: this.$t("singlePage.useway"),
          id: "#reviews",
          icon: "",
        },
      ],
      activeItem: "indication",
      quantitiy: 1,
      selectedImg: "",
      lang: 0,

      products: [],
      product: [],

      dialogShareVisible: false,
      product_Url: "",
      
    };
  },
  components: {
    Title,
    ProductCard,
    tFooter,
    Telegram,
    Facebook,
    Twitter,
    WhatsApp,
    Email,
    Google,
  },

  computed: {
    // ...mapState('product', ['product']),
    ...mapGetters("product", ["getProductById", "getProductByRouterName"]),
  },
  mounted() {
    console.log("Router name: " + this.router_name);
    // console.log("Name: " + this.name)
    window.scrollTo(0, 0);

    this.getProduct();
    this.selectImage(-1);

    this.getOtherProducts();
  },
  methods: {
    ...mapActions("cart", ["addProductToCart"]),

    getProduct() {
      this.product = this.getProductByRouterName(this.router_name);
      console.log("get Product : ", this.product);
      this.product_Url = "https://bmi.uz" + this.$route.fullPath;
      console.log("Product URL: ", this.product_Url);
    },
    getOtherProducts() {
      this.products.push(this.getProductById(1));
      this.products.push(this.getProductById(3));
      this.products.push(this.getProductById(6));
      this.products.push(this.getProductById(7));
    },

    addToCart() {
      this.addProductToCart({
        quantity: this.quantitiy,
        product: this.product,
        lang: this.lang,
      });
    },

    shareProduct() {
      this.dialogShareVisible = true;
    },

    copyURL() {
      navigator.clipboard.writeText(this.product_Url);

    },

    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },

    selectLanguage() {
      if (this.$i18n.locale === "uz") {
        this.lang = 0;
        return 0;
      } else if (this.$i18n.locale === "ru") {
        this.lang = 1;
        return 1;
      } else if (this.$i18n.locale === "en") {
        this.lang = 2;
        return 2;
      }
    },

    selectImage(id) {
      if (id === -1) {
        this.selectedImg = require("@/assets" + this.product.image);
      } else {
        this.selectedImg = require("@/assets" +
          this.product.children_image[id].image);
      }
    },
  },
};
</script>
