<template>
  <div class="navar">
    <!-- Navbar  -->
    <header>
      <!-- Brand Logo  -->
      <a :href="`/${$i18n.locale}/`" class="brand">
        <img
          src="@/images/logo/logo_BioMed.png"
          alt="Biomed Industry"
          class="img-brand"
        />
      </a>

      <!-- Menu Button for Mobile Version -->
      <div
        class="menu-btn"
        :style="isEnable ? 'visibility: visible;' : 'visibility: hidden;'"
      >
        <b-icon
          class="menu-btn-icon-Main"
          icon="justify"
          font-scale="2"
        ></b-icon>
      </div>

      <!-- Navigation -->
      <div class="navigationHome">
        <ul class="nav">
          <li v-if="isEnable" id="home">
            <a :href="`/${$i18n.locale}/home`">{{ $t('nav.home') }}</a>
          </li>
          <li v-if="isEnable" id="product">
            <a
              :href="`/${$i18n.locale}/products`"
              @mouseover="doMouseOver(0)"
              @mouseleave="doMouseLeave(0)"
            >
              {{ $t('nav.product') }}</a
            >
          </li>
          <li v-if="isEnable" id="about">
            <a
              :href="`/${$i18n.locale}/about`"
              @mouseover="doMouseOver(2)"
              @mouseleave="doMouseLeave(2)"
              >{{ $t('nav.about') }}</a
            >
          </li>
          <li v-if="isEnable" id="contact">
            <a
              :href="`/${$i18n.locale}/contact`"
              @mouseover="doMouseOver(1)"
              @mouseleave="doMouseLeave(1)"
              >{{ $t('nav.contact') }}</a
            >
          </li>

          <!-- <li v-if="isEnable" id="faq">
            <a
              :href="`/${$i18n.locale}/faq`"
              @mouseover="doMouseOver(4)"
              @mouseleave="doMouseLeave(4)"
              >{{ $t('nav.faq') }}</a
            >
          </li> -->

          <!-- LANGUAGE -->
          <li v-if="isEnable" id="language">
            <Language />
          </li>
          <!-- CART -->
          <li id="cart" v-if="isEnable">
            <a :href="`/${$i18n.locale}/cart`">
              <b-icon v-if="!cart.length" icon="bag" font-scale="1.2"></b-icon>
              <b-icon
                v-if="cart.length"
                icon="bag-check"
                font-scale="1.2"
              ></b-icon>
            </a>
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<style lang="scss" scoped src="@/css/navbar_mainpage_sytle.scss"></style>

<script>
import Language from '../Titan/t-language-switcher.vue'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      urlProduct: 'products',
      isEnable: true,

      useClass: 'itemOver',
      useStyle: false,
      useSytleArray: [false, false, false, false, false]
    }
  },
  computed: {
    ...mapState('cart', ['cart'])
  },
  components: {
    Language
  },
  methods: {
    goUrl: function (goLink) {
      window.location.href = '`/${$i18n.locale}/ `' + goLink
    },
    doMouseOver (id) {
      console.log(id)
      this.useStyle = true
      this.useSytleArray[id] = true
      this.useClass = 'flex : 1.5;'
    },
    doMouseLeave (id) {
      this.useStyle = false
      this.useSytleArray[id] = false
      this.useClass = 'flex : 1;'
    }
  }
}
</script>
