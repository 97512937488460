import { render, staticRenderFns } from "./t-contact.vue?vue&type=template&id=35a6e862&scoped=true&"
import script from "./t-contact.vue?vue&type=script&lang=js&"
export * from "./t-contact.vue?vue&type=script&lang=js&"
import style0 from "@/css/Main_style.scss?vue&type=style&index=0&id=35a6e862&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a6e862",
  null
  
)

export default component.exports