export const addProductToCart =({commit, dispatch}, {quantity, product, lang }) =>{
    commit('ADD_TO_CART', {quantity, product });

    let msg = "";
    if(lang == 0){
        msg = 'Mahsulot savatchaga qoshildi.'
    }
    else if(lang == 1){
        msg = 'Товар добавлен в корзину.'
    }
    else{
        msg = 'Product added to cart.'
    }

    dispatch('addNotification', {
        type: 'success',
        // message: 'Product added to cart.',
        message: msg,
    }, {root: true})
}

export const incrementItem = ({ commit }, index) => {
    commit('INCREMENT', index)
}

export const decrementItem = ({commit}, index) => {
    commit('DECREMENT', index)
}

export const deleteItem = ({commit, dispatch}, index) => {
    commit('REMOVE_FROM_CART', index),

    dispatch('addNotification', {
        type: 'warning',
        message: 'Товар удален из корзины.'
    },{root: true})
}

export const deleteAllItems = ({commit}) =>{
    commit('REMOVE_FROM_CART_ALL')
}

