<template>
  <div id="my-products" class="col-sm-6 col-md-3 col-lg-3">
    <div class="shop-item">
      <div class="shop-item-image">
        <img
          :src="require('@/assets' + product.image)"
          alt="Accessories Pack"
        />

        <div class="shop-item-detail">
          <button class="btn btn-dark" @click="addToCart()">
            {{ $t("addtocart") }}
          </button>
        </div>
      </div>
      <h4 class="shop-item-title font-alt">
        <router-link
          :to="{
            name: 'singleproduct',
            params: { id: product.id, name: product.name[selectLanguage()] },
          }"
        >
          <span @click="addToState"> {{ product.name[selectLanguage()] }}</span>
        </router-link>
      </h4>
      <h4 class="shop-item-cost"></h4>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["product"],
    data() {
    return {
      lang: 0,
    };
  },
  methods: {
    ...mapActions("cart", ["addProductToCart"]),
    ...mapActions("product", ["addToProductState"]),

    addToState() {
      this.addToProductState(this.product);
    },
    addToCart() {
      this.addProductToCart({
        quantity: 1,
        product: this.product,
        lang: this.lang,

      });
    },
    selectLanguage() {
      if (this.$i18n.locale === "uz") {
        this.lang = 0;
        return 0;
      } else if(this.$i18n.locale === "ru") {
        this.lang = 1;
        return 1;
      } else if(this.$i18n.locale === "en"){
        this.lang = 2;
        return 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="@/css/Main_style.scss"></style>
