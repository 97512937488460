// type: ['Kapsula','Капсула','Capsule'],  - type_index : 0,
// type: ['Tabletka','Таблетка','Tablet'], - type_index : 1,
// type: ['Sirop','Сироп','Sirop'],        - type_index : 2,
// type: ['Paket','Саше','Sachet'],        - type_index : 3,

export default {
  product: null,

  products: [
    //#region 1) IMUNITET UCHUN

    // Product AVITSERON
    // ID : 1
    {
      id: 1,
      router_name: 'Avitseron',
      name: ['Avitseron', 'Авицерон', 'Avitseron'],
      cost: '',
      title: [
        "Immunitetning og'ishlarini tabiiy ravishda tuzatish",
        'Естественная коррекция отклонений иммунитета',
        'Natural correction of immunity developments'
      ],
      image: '/products/1280x1010/Avitseron_1280x1010.jpg',
      link: '/singleproduct',
      category: 'energy',
      type: ['Kapsula', 'Капсула', 'Capsule'],
      type_index: 0,
      view: [
        'Chiqarilish shakli: Kapsula, 30 dona',
        'Форма выпуска: Капсула, по 30 штук в упаковке',
        'Release form: Capsule, 30 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Avitseron_1280x1010.jpg'
        }
      ],
      subtitle: [
        "Avitseron organizmning maxsus himoya mexanizmlarini mustahkamlaydi, immun tizimining zaiflashishi bilan kechadigan turli surunkali kasalliklarni kompleks davolashda foydalanish tavsiya etiladi. Kimyoterapiya va radiatsiya terapiyasini o'tkazgan bemorlarning imunitetini qayta tiklash va ish faoliyatini yaxshilashiga yordam beradi.\n Avitseron - gumoral va kuchli faollashtiruvchi hujayra immuniteti, shuningdek, organizmning turli etiologiyalarning yuqumli kasalliklariga chidamliligini oshirishga yordam beradigan fagotsitozdir",
        '«АВИЦЕРОН» стимулирует собственные защитные механизмы организма, рекомендуется применять в комплексной терапии различных хронических заболеваний, сопровождающихся ослаблением функций иммунитета. Способствует восстановлению и улучшению показателей иммунитета у больных после перенесенной химиотерапии и лучевой терапии.\n «АВИЦЕРОН» является мощным активатором гуморального и клеточного звена иммунитета, а также фагоцитоза, что способствует повышению сопротивляемости организма к инфекционным заболеваниям различной этиологии',
        ''],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Surunkali gepatit (B, C)",
            "Urogenital infektsiyalar",
            "Urogenital infektsiyalar",
            "VICH - infektsiyasi",
            "Kimyoterapiya olishdan oldin",
            "SARS va grippning oldini olish",
            "Surunkali brutsellyoz",
            "Surunkali: xoletsistit, gastrit, kolit",
            "Surunkali bronxit (allergik komponentsiz)",
          ]
        },
        {
          id: 1,
          data: [
            'Хронические гепатиты (B, С)',
            'Урогенитальные инфекции',
            'TORCH инфекции',
            'ВИЧ - инфекция',
            'Перед получением химиотерапии',
            'Профилактика ОРВИ и гриппа',
            'Хронический бруцеллёз',
            'Хроническом: холецистите, гастрите, колите',
            'Хронический бронхит (без аллергического компонента)'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: "To'qimalarning gidrolizati", Miqdori: '1 mg' },
            { Tarkibi: "S Vitamini", Miqdori: '25 mg' },
            { Tarkibi: "Mentol", Miqdori: '5 mg' },
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Тканевой гидролизат', Экстракт: '1 мг' },
            { Состав: 'Аскорбиновая кислота', Экстракт: '25 мг' },
            { Состав: 'Ментол', Экстракт: '5 мг' },
          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Suv bilan ichga qabul qilish orqali qo'llaniladi",
            "Ovqatlanish vaqtida yoki ovqatlanishdan so'ng",
            "Kattalar : 1-2 kapsuladan kuniga 2 maxal",
            "5 yoshdan katta bolalar : 1 kapsuladan kuniga 1 maxal",
            "Qabul qilish muddati : 60 kun",
            "Giperatsid gastriti bor bemorlarga ehtiyotkorlik bilan qo'llash tavsiya etiladi",
            "Maxsus ko'rsatmalar: homilador ayollarda foydalanish bo'yicha ma'lumotlar yo'q"
          ],

          contraindications: [
            "Allergik reaktsiyalar va bronxial astma kasalliklarining o'tkir davrida foydalanish tavsiya etilmaydi",
            "Miyokardning jiddiy shikastlanishida tavsiya etilmaydi",
            "Preparat komponentlariga shahsiy o'zlashtiraolmaslik"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, во время или после еды, запивая стаканом воды',
            'Взрослым: по 1 - 2 капсуле 2 раза в день',
            'Детям с 5 лет: по 1 капсуле 1 раз в день',
            'Длительность приёма: 60 дней',
            'С осторожностью: при гиперацидном гастрите',
            'Особые указания: нет данных о применении у беременных'
          ],

          contraindications: [
            'не рекомендуется применять в остром периоде заболевания, при острых аллергических реакциях и бронхиальной астме',
            'При тяжелых поражениях миокарда',
            'Индивидуальная непереносимость к компонентам препарата'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    // Product SINK SSD3
    // ID : 2
    {
      id: 2,
      router_name: 'Sink_SSD3',
      name: ['Sink S.S.D3', 'Цинк С.С.Д3', 'Sink S.S.D3'],
      cost: '',
      title: [
        'Immunitet uchun muhim vitaminlar va minerallar',
        'Важные витамины и минералы для поддержки иммунной системы',
        'Важные витамины и минералы для поддержки иммунной системы'
      ],
      image: '/products/1280x1010/SinkSDD_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: 'energy',
      type: ['Tabletka', 'Таблетка', 'Tablet'],
      type_index: 1,
      view: [
        'Chiqarilish shakli: Tabletka, 30 dona',
        'Форма выпуска: Таблетки, по 30 штук в упаковке',
        'Release form: Tablets, 30 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/SinkSDD_Gray_1280x1010.jpg'
        },
        {
          id: 1,
          image: '/products/photostudio/b_Sink_front.jpeg'
        },
        {
          id: 2,
          image: '/products/photostudio/b_Sink_left.jpeg'
        },
        {
          id: 3,
          image: '/products/photostudio/b_Sink_2.jpeg'
        }
      ],
      subtitle: [
        "Sink SSD3 - immun tizimini mustaxkamlaydi, turli xil infeksion kasalliklarga, hamda grip va o'tkir respirator virusli infeksiyalarga qarshi kurashuvchanligini oshiradi. Kuchli anioksidant, kollagen sintezlanishida ishtirok etadi, insulin sekresiyasini kuchaytiradi, xolesterin metabolizmini meyorlashtiradi. Sink SDD3 - steroid gormonlarni sintezlanishida ishtirok etadi, qarilik jarayonini susaytiradi, V gurux vitaminlarini o'zlashtirilishini yaxshilaydi, Tomir devorlarini mustahkamlaydi, qon tozalash xususiyatiga ega, erkin radikallarni bartaraf etadi, uglevod va oqsil almashinuvini faollashtiradi.",
        'Цинк ССД3 - способствует укреплению иммунной системы, повышает сопротивляемость к инфекциям различной этиологии, в том числе гриппа, острых респираторных вирусных инфекций. Оказывает мощный антиоксидантный эффект, участвует в синтезе коллагена, стимулирует секрецию инсулина, нормализует метаболизм холестерина. Цинк ССД3 -  участвует в синтезе стероидных гормонов, замедляет процессы старения, способствует улучшению усвоения витаминов группы В. Укрепляет стенки сосудов, способствует очищению крови и устранению свободных радикалов, активирует углеводный и белковый обмен',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            'Immunitet mustahkamlashga, hamda turli shamollash va virusli kasalliklar rivojlanish xavfini kamaytirish uchun',
            'Antioksidant ximoya',
            'Teri, soch va tirnoqlar salomatligi uchun',
            "Yurak-qon tomir tizimini faoliyatini sog'lom saqlashda va organizmning reproduktiv hususiyatini qo'llab quvatlashishda"
          ]
        },
        {
          id: 1,
          data: [
            'Укрепления иммунитета и снижения риска развития простудных и вирусных заболеваний',
            'Антиоксидантная защита',
            'Для красоты кожи, волос и ногтей',
            'Поддержания здоровья сердечно-сосудистой системы и репродуктивной функции организма'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: 'Sink laktati', Miqdori: '30mg' },
            { Tarkibi: 'D3 vitamini', Miqdori: '400ME' },
            { Tarkibi: 'C vitamini ', Miqdori: '50mg' },
            { Tarkibi: 'Selen', Miqdori: '37mkg' }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Цинк лактат', Экстракт: '30 мг' },
            { Состав: 'Витамин Д3', Экстракт: '400 МЕ' },
            { Состав: 'Витамин С', Экстракт: '50 мг' },
            { Состав: 'Селен', Экстракт: '37 мкг' }
          ]
        },
        //English language
        {
          id: 2,
          items: [
            { Composition: 'Цинк лактат', Extract: '30 mg' },
            { Composition: 'Vitamin D3', Extract: '400 МЕ' },
            { Composition: 'Vitamin С', Extract: '50 mg' },
            { Composition: 'Селен', Extract: '37 mkg' }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ovqatlanish vaqtida ichga qabul qilish orqali qo'llaniladi",
            "Kattalarga va 16 yoshdan oshgan o'smirlarga: 1-2 tabletkadan kuniga 2-3 maxal",
            "3 yoshdan 16 yoshgacha bo'lgan bolalarga: 0,5 - 1 tabletkadan kuniga 1-2 maxal",
            'Berilish davomiyligi : 10 kundan 2 oygacha'
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtiraolmaslik",
            "Boshqa dorilar bilan o'zaro ta'siri: polivitaminlar bilan birgalikda qabul qilish tavsiya etilmaydi",
            "Homiladorlik va emizish davrida qo'llanilishi: homiladorlik va emizish davrida berilishi haqida ma'lumot yo'q"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Назначается внутрь во время еды',
            'Взрослым и детям старше 16 лет: по 1 - 2 таблетке, 2 - 3 раза в день',
            'Детям с 3 до 16 лет: по 0,5 – 1 таблетке, 1 - 2 раза в день',
            'Длительность приема: от 10 дней до 2 месяцев'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата',
            'Взаимодействие с другими лекарственными средствами: не рекомендуется одновременный приём поливитаминных комплексов',
            'Применение при беременности и в период лактации: нет данных о применении в период беременности и лактации'
          ]
        },
        {
          //English language
          id: 2,
          data: [
            'Назначается внутрь во время еды',
            'Взрослым и детям старше 16 лет: по 1 - 2 таблетке, 2 - 3 раза в день',
            'Детям с 3 до 16 лет: по 0,5 – 1 таблетке, 1 - 2 раза в день',
            'Длительность приема: от 10 дней до 2 месяцев'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата',
            'Взаимодействие с другими лекарственными средствами: не рекомендуется одновременный приём поливитаминных комплексов',
            'Применение при беременности и в период лактации: нет данных о применении в период беременности и лактации'
          ]
        }
      ]
    },

    // Product IMMUN 5
    // ID : 3
    {
      id: 3,
      router_name: 'Immun_5',
      name: ['Immun 5', 'ИММУН-5 ', 'Immun 5'],
      cost: '',
      title: [
        "Immunitetning og'ishlarini tabiiy tuzatuvchi vosita",
        'Уникальная разработка отечественных специалистов естественная коррекция отклонений иммунитета', ''],
      image: '/products/1280x1010/Immun_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: 'energy',
      type: ['Kapsula', 'Капсула', 'Capsule'],
      type_index: 0,
      view: [
        'Chiqarilish shakli: tabletka, 30 dona',
        'Форма выпуска: таблетки, по 30 штук в упаковке',
        'Release form: tablets, 30 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Immun_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        "Immun 5 - bu tabiiy biologik faol moddalarning muvozanatli tarkibi bo'lib, ularning harakati tananing immunitet tizimini mustahkamlashga qaratilgan. \n Immun 5 fagotsitozning kuchayishi va tugallanishiga hissa qo'shadi, immunitet va makrofaglarning B-tizimi orqali bilvosita virusga qarshi ta'sirga ega, immunitet hujayralarining surunkali anergiyasini yengillashtiradi, bu tananing turli etiologiyali yuqumli kasalliklarga chidamliligini oshirishga yordam beradi. \nImmun 5 oshqozon-ichak traktining motor va sekretor funktsiyalarini yumshoq tarzda unumlashtiradi va normallashtiradi, oshqozon va ichak shilliq qavatida yallig'lanishga qarshi va tiklovchi ta'sirga ega. U umumiy tonik ta'sirga ega, ishtahani va ish faoliyatini yaxshilaydi. \n Immun 5 turli etiologiyali yuqumli kasalliklarning oldini olish va davolash uchun tavsiya etiladi.",
        'Иммун 5 представляет собой сбалансированный состав натуральных биологически активных веществ, действие которых, направлено на укрепление иммунной системы организма. \n Иммун 5 способствует усилению и завершению фагоцитоза, оказывает опосредованное противовирусное действие через В-систему иммунитета и макрофагоф, снимает хроническую анергию иммунных клеток, что сбособствует повышению сопротивляемости организма к инфекционным заболеваниям различной этиологии. \n Иммун 5 мягко стимулирует и нормализует моторную и секреторную функции желудочно - кишечного тракта, оказывает противовоспалительное и регенерирующее действие на слизистую оболочку желудка и кишечника. Оказывает общее тонизирующее дейтсвие, улучшает аппетит и работоспособность. Иммун 5 рекомендуется применять для профилактики и лечения инфекционных заболеваний различной этиологии.',
        ''],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Surunkali gepatit (B, C)",
            "VICH - infektsiyasi",
            "TORCH - infektsiyalar (CMV, Gerpes, Toksoplazmoz, qizilcha va boshqalar)",
            "COVID-19 (aloqadorlar uchun), Gripp, SARS ning oldini olish",
            "Surunkali: Laringit, Faringit, Rinit, Otit, Tonzillit",
            "Surunkali: Gepatokolsistit, Gastrit, Kolit",
            "Surunkali bronxit (allergik komponentsiz)",
            "Surunkali urogenital infektsiyalar"
          ]
        },
        {
          id: 1,
          data: [
            'Хронические гепатиты (B, С)',
            'ВИЧ - инфекция',
            'TORCH - инфекции (ЦМВ, герпес, токсоплазмоз, краснуха и др.)',
            'Профилактика COVID-19 (у контактных), гриппа, ОРВИ',
            'Хронический: ларингит, фарингит, ринит, отит, тонзиллит',
            'Хронический: гепатохолецистит, гастрит, колит',
            'Хронический бронхит (без аллергического компонента)',
            'Хронические урогенитальные инфекции'
          ]
        },
        {
          id: 2,
          data: [
            'Chronic hepatitis (B, C)',
            'HIV infection',
            'TORCH - infections (CMV, herpes, toxoplasmosis, rubella, etc.)',
            'Prevention of COVID-19 (for contacts), influenza, SARS',
            'Chronic: laryngitis, pharyngitis, rhinitis, otitis media, tonsillitis',
            'Chronic: hepatocholecystitis, gastritis, colitis',
            'Chronic bronchitis (without allergic component)',
            'Chronic urogenital infections'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            {
              Tarkibi: "Faol moddalar: To'qimalarning gidrolizati",
              Miqdori: '1 mg'
            },
            { Tarkibi: 'Yordamchi moddalar: S Vitamini', Miqdori: '25 mg' },
            { Tarkibi: 'Yordamchi moddalar: Mentol', Miqdori: '5 mg' }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            {
              Состав: 'Действующее вещество: Тканевой гидролизат',
              Экстракт: '1 мг'
            },
            {
              Состав: 'Вспомогательные вещества: Аскорбиновая кислота',
              Экстракт: '25 мг'
            },
            { Состав: 'Вспомогательные вещества: Ментол', Экстракт: '5 мг' }
          ]
        },
        //English language
        {
          id: 2,
          items: [
            {
              Composition: 'Active ingredient: Tissue hydrolyzate',
              Extract: '1mg'
            },
            {
              Composition: 'Excipients: Ascorbic acid',
              Extract: '25mg'
            },
            {
              Composition: 'Excipients: Menthol',
              Extract: '5mg'
            }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ovqatlanish vaqtida ichga qabul qilish orqali qo'llaniladi",
            'Kattalarga 1-2 kapsuladan kuniga 2 maxal',
            "5 yoshdan 12 yoshgacha bo'lgan bolalarga: 1 kapsuladan kuniga 1 maxal",
            "13 yoshdan 17 yoshgacha bo'lgan o'smirlarga: 1 kapsuladan kuniga 2 maxal",
            'Berilish davomiyligi : ',
            "- 60 kun - TORCH infektsiyasi, surunkali - urogenital infektsiyalar, gepatokolsistit, gastrit, kolit, laringit, faringit, rinit, otit, tonzillit*",
            "- 1-2 oy - COVID-19 (aloqa uchun), gripp, SARS, ichak infektsiyalari profilaktikasi uchun",
            "- 4-8 oy - OIV, surunkali gepatit (B, C)**",
            "* oylik tanaffusdan keyin kursni takrorlash mumkin",
            "** Virus og'irligini zararsizlantirgunga qadar kurs davomiyligi",
            "30 kunlik tanaffusdan keyin kursni takrorlash mumkin",
            "IMMUN-5 ni etiotrop va/yoki simptomatik terapiya bilan birgalikda qo'llash eng unumli"
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtiraolmaslik",
            "Kasallikning o'tkir bosqichida, o'tkir allergik reaktsiyalar, bronxial astma, miokardning og'ir shikastlanishi",
            "Ehtiyotkorlik talab etiladi : Giperatsid gastriti borlarga"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, во время или после еды',
            'Взрослым по 1 - 2 капсуле 2 раза в день',
            'Детям:   5 - 12 лет по 1 капсуле 1 раз в день',
            '13 - 17 лет по 1 капсуле 2 раза в день',
            'Длительность курса:',
            '- 60 дней - при TORCH-инфекции, хронических - урогенитальных инфекциях, гепатохолецистите, гастрите, колите, ларингите, фарингите, рините, отите, тонзиллите* ',
            '- 1-2 месяцев - для профилактики COVID-19 (у контактных), гриппа, ОРВИ, кишечных инфекций',
            '- 4-8 месяцев - при ВИЧ, хронических гепатитах (В, С)**',
            ' *возможно повторение курса после месячного перерыва',
            ' **длительность курса до нейтрализации вирусной нагрузки',
            'возможно повторение курса, после 30-ти дневного перерыва',
            'Наиболее рационально применять «IMMUN-5» совместно с этиотропной и/или симптоматической терапией'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата, при острых стадиях заболевания, острых аллергических реакциях, бронхиальной астме, тяжелые поражения миокарда',
            'С осторожностью: при гиперацидном гастрите'
          ]
        },
        {
          //English language
          id: 2,
          data: [
            'Ingestion, during or after meals',
            'Adults 1-2 capsules 2 times a day',
            'Children: 5 - 12 years 1 capsule 1 time per day',
            '13 - 17 years 1 capsule 2 times a day',
            'Course duration:',
            '- 60 days - with TORCH infection, chronic - urogenital infections, hepatocholecystitis, gastritis, colitis, laryngitis, pharyngitis, rhinitis, otitis media, tonsillitis* ',
            '- 1-2 months - for the prevention of COVID-19 (for contacts), influenza, SARS, intestinal infections',
            '- 4-8 months - with HIV, chronic hepatitis (B, C)**',
            ' *It is possible to repeat the course after a monthly break',
            ' **the duration of the course until the neutralization of the viral load',
            'It is possible to repeat the course after a 30-day break',
            'It is most rational to use "IMMUN-5" in conjunction with etiotropic and / or symptomatic therapy'
          ],

          contraindications: [
            'Individual intolerance to the components of the drug, in acute stages of the disease, acute allergic reactions, bronchial asthma, severe myocardial damage',
            'With caution: with hyperacid gastritis'


          ]
        }
      ]
    },
    //#endregion

    //#region 2) TINCHLANTIRUVCHI , METOBALICHESKIY
    // Product Anavil
    // ID : 5
    {
      id: 5,
      router_name: 'Anevil_tablet',
      name: ['Anevil', 'Анэвил', 'Anevil'],
      cost: '',
      title: [
        "Dorivor o'simliklardan tayyorlangan tinchlantiruvchi vosita",
        'Успокоительное средство растительного происхождения',
        'Herbal sedative'
      ],
      image: '/products/mainview/b_Anevil_MainView.jpg',
      link: '/singleproduct',
      category: 'herbal',
      type: ['Tabletka', 'Таблетка', 'Tablet'],
      type_index: 1,
      view: [
        'Chiqarilish shakli: Tabletka, 30 dona',
        'Форма выпуска: Таблетки, по 30 штук в упаковке',
        'Form of release: Tablets, 30 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/mainview/b_Anevil_MainView.jpg'
        }
      ],
      subtitle: [
        "Sedativ, tinchlantiruvchi, spazmolitik, gipotenziv ta'sir xususiyatiga ega. Uglevodlar va yog'lar almashuviga yaxshi ta'sir ko'rsatadi, glyukoza, piruvvat va sut kislotasini hamda xolsterin, umumiy lipidlarni qondagi miqdorini kamaytiradi, oqsil almashinuvini yaxshilaydi. Toj tomirlarini kengaytiruvchi ta'sirga ega, nerv qo'zg'aluvchanligini va charchoqni bartaraf etadi. Qon bosimini normallashtiradi, bosh og'rig'ini bartaraf qiladi, uyqu va umumiy hissiyotni yaxshilaydi.",
        'Оказывает седативное, успокоительное, спазмолитическое, гипотензивное действие. Благоприятно воздействует на обмен углеводов и жиров, снижает уровень глюкозы, пирувата и молочной кислоты, а также уровень холестерина в крови. Расширяет коронарные сосуды, нормализует кровяное давление. Борется с усталостью и нервным напряжением, уменьшает головную боль, улучшает сон и общее самочувствие',
        'It has a sedative, sedative, antispasmodic, hypotensive effect. Favourably, affects the metabolism of carbohydrates and fats, reduces the level of glucose, pyruvate and lactic acid, as well as the level of cholesterol in the blood. Expands coronary vessels, normalizes blood pressure. Fights fatigue and nervous tension, reduces headaches, improves sleep and overall well-being'
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            'Nevrasteniya',
            "Psixoemotsional zo'riqqanda(imtixon vaqtida, to'qnashuv va boshqa vaziyatlarda)",
            "Bolalar va o'smirlar xulqini o'zgarishida",
            'Nevroz, nevroz kabi xolatlar va vegeto-tomir distoniyasida',
            "Bosh og'rig'i, asabiylashish oqibatida",
            'Migren, uyqusizlik(yengil shakli)',
            'Neyrosirkulyator distoniyasida va klimakterik sindromlarda(simptomatik davolash vositasi sifatida)'
          ]
        },
        {
          id: 1,
          data: [
            'Неврастения',
            'Психоэмоциональное напряжение (в период экзаменов, конфликтных и т.п ситуациях )',
            'Девиантные формы поведения детей и подростков',
            'Неврозы, неврозоподобные состояния, ВСД',
            'Головные боли, обусловленные нервным наприжением',
            'Мигрень, бессонница (легкие  формы)',
            'Нейроциркуляторная дистония и климактерический синдром (в качестве симптоматического средства )'
          ]
        },
        {
          id: 2,
          data: [
            'Neurasthenia',
            'Psycho-emotional stress (during exams, conflict, etc.situations)',
            'Deviant forms of behaviour of children and adolescents',
            'Neuroses, neurosis-like states, VVD',
            'Headaches caused by nervous tension',
            'Migraine, insomnia (mild forms)',
            'Neurocirculatory dystonia and climacteric syndrome (as a symptomatic remedy)'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: 'Melissa ekstrakti', Miqdori: '40mg' },
            { Tarkibi: 'Gletsin', Miqdori: '20mg' },
            { Tarkibi: 'Valeriana ekstrakti', Miqdori: '30mg' },
            { Tarkibi: 'Yalpiz qalampirmunchoq ekstrakti', Miqdori: '30mg' },
            { Tarkibi: "Do'lana mevalari ekstrakti", Miqdori: '40mg' },
            { Tarkibi: "Ota-ona o'simligining ekstrakti", Miqdori: '40mg' }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Мелиссы экстракт', Экстракт: '40мг' },
            { Состав: 'Глицин', Экстракт: '20мг' },
            { Состав: 'Валерианы экстракт', Экстракт: '30мг' },
            { Состав: 'Мяты перечной экстракт', Экстракт: '30мг' },
            { Состав: 'Боярышника экстракт', Экстракт: '40мг' },
            { Состав: 'Пустырника экстракт', Экстракт: '40мг' }
          ]
        },
        //English language
        {
          id: 1,
          items: [
            { Composition: 'Melissa officinalis extract', Extract: '40mg' },
            { Composition: 'Glycine', Extract: '20mg' },
            { Composition: 'Valerianae extract', Extract: '30mg' },
            { Composition: 'Menta x piperita extract', Extract: '30mg' },
            { Composition: 'Crataegus extract ', Extract: '40mg' },
            { Composition: 'Leonurus extract', Extract: '40mg' }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            'Ovqatlanishdan 20 daqiqa oldin',
            'Suv bilan ichilishi lozim',
            'Kattalarga : 1-2 tabletkadan kuniga 2 maxal',
            '10 yoshdan katta bolalarga: 1 tabletkadan, kuniga 1-2 maxal',
            'Davolash kursi : 1-2 oy'
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Прием внутрь',
            'За 20 мин до еды',
            'Необходимо запивать водой',
            'Взрослым : по 1-2 таблетке, 2 раза в день',
            'Детям старше 10 лет : по 1 таблетке 1-2 раза в день',
            'Курс лечения : 1-2 месяца'
          ],

          contraindications: [
            'Индивидуальная непереносимость к компонентам препарата'
          ]
        },
        {
          //English language
          id: 2,
          data: [
            'Ingestion',
            '20 minutes before meals',
            'Must be taken with water',
            'Adults: 1 - 2 tablets, 2 times a day',
            'Children over 10 years old: 1 tablet, 1 to 2 times a day',
            'Course of treatment: 1 - 2 months'
          ],

          contraindications: [
            'Individual intolerance to the components of the drug'
          ]
        }
      ]
    },

    // Product Anavil Sirop
    // ID : 6
    {
      id: 6,
      router_name: 'Anevil_sirop',
      name: ['Anevil', 'Анэвил', 'Anevil'],
      cost: '',
      title: [
        "Dorivor o'simliklardan tayyorlangan tinchlantiruvchi vosita",
        'Успокоительное средство растительного происхождения',
        'Herbal sedative'
      ],
      image: '/products/1280x1010/Anavil_Sirop_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: 'herbal',
      type: ['Sirop', 'Сироп', 'Sirop'],
      type_index: 2,
      view: [
        'Chiqarilish shakli: Sirop, 150ml',
        'Форма выпуска: Сироп, 150мл',
        'Form of release: Sirop, 150ml'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Anavil_Sirop_Gray_1280x1010.jpg'
        },
        {
          id: 7,
          image: '/products/photostudio/b_AnevilSirop_left.jpeg'
        },
        {
          id: 5,
          image: '/products/photostudio/b_AnevilSirop_right.jpeg'
        },
        {
          id: 6,
          image: '/products/photostudio/b_AnevilSirop_3.jpeg'
        }
      ],
      subtitle: [
        "Sedativ, tinchlantiruvchi, spazmolitik, gipotenziv ta'sir xususiyatiga ega. Uglevodlar va yog'lar almashuviga yaxshi ta'sir ko'rsatadi, glyukoza, piruvvat va sut kislotasini hamda xolsterin, umumiy lipidlarni qondagi miqdorini kamaytiradi, oqsil almashinuvini yaxshilaydi. Toj tomirlarini kengaytiruvchi ta'sirga ega, nerv qo'zg'aluvchanligini va charchoqni bartaraf etadi. Qon bosimini normallashtiradi, bosh og'rig'ini bartaraf qiladi, uyqu va umumiy hissiyotni yaxshilaydi.",
        'Оказывает седативное, успокоительное, спазмолитическое, гипотензивное действие. Благоприятно воздействует на обмен углеводов и жиров, снижает уровень глюкозы, пирувата и молочной кислоты, а также уровень холестерина в крови. Расширяет коронарные сосуды, нормализует кровяное давление. Борется с усталостью и нервным напряжением, уменьшает головную боль, улучшает сон и общее самочувствие',
        'It has a sedative, sedative, antispasmodic, hypotensive effect. Favourably, affects the metabolism of carbohydrates and fats, reduces the level of glucose, pyruvate and lactic acid, as well as the level of cholesterol in the blood. Expands coronary vessels, normalizes blood pressure. Fights fatigue and nervous tension, reduces headaches, improves sleep and overall well-being'
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            'Nevrasteniya',
            "Psixoemotsional zo'riqqanda(imtixon vaqtida, to'qnashuv va boshqa vaziyatlarda)",
            "Bolalar va o'smirlar xulqini o'zgarishida",
            'Nevroz, nevroz kabi xolatlar va vegeto-tomir distoniyasida',
            "Bosh og'rig'i, asabiylashish oqibatida",
            'Migren, uyqusizlik(yengil shakli)',
            'Neyrosirkulyator distoniyasida va klimakterik sindromlarda(simptomatik davolash vositasi sifatida)'
          ]
        },
        {
          id: 1,
          data: [
            'Неврастения',
            'Психоэмоциональное напряжение (в период экзаменов, конфликтных и т.п ситуациях )',
            'Девиантные формы поведения детей и подростков',
            'Неврозы, неврозоподобные состояния, ВСД',
            'Головные боли, обусловленные нервным наприжением',
            'Мигрень, бессонница (легкие  формы)',
            'Нейроциркуляторная дистония и климактерический синдром (в качестве симптоматического средства )'
          ]
        },
        {
          id: 2,
          data: [
            'Neurasthenia',
            'Psycho-emotional stress (during exams, conflict, etc.situations)',
            'Deviant forms of behaviour of children and adolescents',
            'Neuroses, neurosis-like states, VVD',
            'Headaches caused by nervous tension',
            'Migraine, insomnia (mild forms)',
            'Neurocirculatory dystonia and climacteric syndrome (as a symptomatic remedy)'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: 'Melissa ekstrakti', Miqdori: '40mg' },
            { Tarkibi: 'Gletsin', Miqdori: '20mg' },
            { Tarkibi: 'Valeriana ekstrakti', Miqdori: '30mg' },
            { Tarkibi: 'Yalpiz qalampirmunchoq ekstrakti', Miqdori: '30mg' },
            { Tarkibi: "Do'lana mevalari ekstrakti", Miqdori: '40mg' },
            { Tarkibi: "Ota-ona o'simligining ekstrakti", Miqdori: '40mg' }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Мелиссы экстракт', Экстракт: '40мг' },
            { Состав: 'Глицин', Экстракт: '20мг' },
            { Состав: 'Валерианы экстракт', Экстракт: '30мг' },
            { Состав: 'Мяты перечной экстракт', Экстракт: '30мг' },
            { Состав: 'Боярышника экстракт', Экстракт: '40мг' },
            { Состав: 'Пустырника экстракт', Экстракт: '40мг' }
          ]
        },
        //English language
        {
          id: 1,
          items: [
            { Composition: 'Melissa officinalis extract', Extract: '40mg' },
            { Composition: 'Glycine', Extract: '20mg' },
            { Composition: 'Valerianae extract', Extract: '30mg' },
            { Composition: 'Menta x piperita extract', Extract: '30mg' },
            { Composition: 'Crataegus extract ', Extract: '40mg' },
            { Composition: 'Leonurus extract', Extract: '40mg' }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            'Ovqatlanishdan 20 daqiqa oldin',
            'Suv bilan ichilishi lozim',
            'Kattalarga : ...dan kuniga 2 maxal',
            '10 yoshdan katta bolalarga: ...dan, kuniga 1-2 maxal',
            'Davolash kursi : 1-2 oy'
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Прием внутрь',
            'За 20 мин до еды',
            'Необходимо запивать водой',
            'Взрослым : по ..., 2 раза в день',
            'Детям старше 10 лет : ... 1-2 раза в день',
            'Курс лечения : 1-2 месяца'
          ],

          contraindications: [
            'Индивидуальная непереносимость к компонентам препарата'
          ]
        },
        {
          //English language
          id: 2,
          data: [
            'Ingestion',
            '20 minutes before meals',
            'Must be taken with water',
            'Adults: 1 - 2 tablets, 2 times a day',
            'Children over 10 years old: 1 tablet, 1 to 2 times a day',
            'Course of treatment: 1 - 2 months'
          ],

          contraindications: [
            'Individual intolerance to the components of the drug'
          ]
        }
      ]
    },

    // Product LK TIN
    // ID : 7
    {
      id: 7,
      router_name: 'LK_Tin_capsule',
      name: ['LK Tin', 'ЛК Тин', 'LK Tin'],
      cost: '',
      title: ['Metabolik mahsulot', 'Метаболическое средство', ''],
      image: '/products/1280x1010/LKTin_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: 'energy',
      type: ['Kapsula', 'Капсула', 'Capsule'],
      type_index: 0,
      view: [
        'Chiqarilish shakli: Kapsula, 30 dona',
        'Форма выпуска: Капсула, по 30 штук в упаковке',
        'Release form: Capsule, 30 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/LKTin_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        "LK-TIN oqsil va yog' almashinuvini, to'qimalar energiya ta'minotini, ovqat hazm qilish tizimining sekretsiyasi va fermentativ (oshqozon va ichak) faolligini oshiradi hamda oziq-ovqatninig so'rilishini yaxshilaydi.\n Bazal metabolizmni normallashtiradi, ortiqcha tana vaznini kamaytiradi va skelet mushaklaridagi yog 'miqdorini kamaytiradi. Jismoniy faoliyatga qarshilik chegarasini oshiradi, laktik atsidoz darajasini pasaytiradi va uzoq muddatli jismoniy zo'riqishlardan keyin ish faoliyatini tiklaydi.",
        '«ЛК-ТИН» улучшает белковый и жировой обмен, энергообеспечение тканей, повышает секрецию и ферментативную активность пищевари-тельных соков (желудочного и кишечного), улучшает усвоение пищи. Нормализует повышенный основной обмен, снижает избыточную массу тела и уменьшает содержание жира в скелетной мускулатуре. Повышает порог резистентности к физической нагрузке, уменьшает степень лактат- ацидоза и восстанавливает работоспособность после длительных физических нагрузок.',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "karnitin yetishmasligi yoki uning yo'qolishi ortishi bilan kechadigan kasalliklar",
            "chidamlilikni oshirish, charchoqni kamaytirish",
            "bolalarda o'sishning kechikishi va tana vaznining etishmasligida",
            "erta va yangi tug'ilgan chaqaloqlarni emizishda (vaznning kam ortishi)",
            "miya qon aylanishining buzilishi (INSULT)",
            "kardiyomiyopatiya, yurakning ishemik kasalligi (Stenokardiya, infarktdan keyingi holatlar)",
            "tana yog'ini kamaytirish va mushak massasini oshirish"


          ]
        },
        {
          id: 1,
          data: [
            'заболевания, сопровождающиеся недостатком карнитина или его повышенной потерей',
            'для повышения выносливости, снижения утомляемости',
            'при задержке роста и недостатке массы тела у детей',
            'при выхаживании недоношенных и новорожденных (низкая прибавка массы тела)',
            'нарушения мозгового кровообращения (инсульт)',
            'при кардиомиопатии, ИБС (стенокардия, постинфарктные состояния',
            'для редукции жировой массы тела и увеличения мышечной массы'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: "L-karnitin", Miqdori: '500 mg' },
            { Tarkibi: "Askorbin kislotasi (Vitamin C)", Miqdori: '50 mg' },
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'L-карнитин', Экстракт: '500 мг' },
            { Состав: 'Аскорбиновая кислота (витамин С)', Экстракт: '50 мг' }
          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali, ovqatlangandan 30 minutdan so'ng qabul qilinadi",
            "Suv bilan ichish lozim",
            "Kattalarga: 1 ta kapsuladan kuniga 2-3 maxal",
            "13 yoshdan 18 yoshgacha bolalarga : 1 ta kapsuladan kuniga 1-2 maxal",
            "3 yoshdan 12 yoshgacha bolalarga : 1/2 kapsuladan kuniga 1 maxal",
            "3 yoshgacha bo'lgan bolalarga : davolovchi shifokor yordamida doza aniqlanadi",
            "Davolash kurdi : 1-2 oy",
            "Mutaxassis bilan maslahatlashish tavsiya etiladi"
          ],

          contraindications: [
            "Preparat komponentlariga shaxsiy o'zlashtira olmaslik"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, через 30 минут после еды, запивая водой',
            'Взрослым: по 1 капсуле 2 - 3 раза в день',
            'Детям 13 - 18 лет: по 1 капсуле 1 - 2 раза в день',
            'Детям 3 - 12 лет: по 1/2 капсулы 1 раз в день',
            'Длительность приёма: 1 - 2 месяца',
            'Рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'индивидуальная непереносимость к компонентам препарата'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    // Product LK TIN SIROP
    // ID : 8
    {
      id: 8,
      router_name: 'LK_Tin_sirop',
      name: ['LK Tin', 'ЛК Тин', 'LK Tin'],
      cost: '',
      title: ['Metabolik mahsulot', 'Метаболическое средство', ''],
      image: '/products/1280x1010/LKTin_Sirop_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Sirop', 'Сироп', 'Sirop'],
      type_index: 2,
      view: [
        'Chiqarilish shakli: Sirop, 100ml',
        'Форма выпуска: Сироп, 100мл',
        'Release form: Sirop, 100ml'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/LKTin_Sirop_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        "LK-TIN oqsil va yog' almashinuvini, to'qimalar energiya ta'minotini, ovqat hazm qilish tizimining sekretsiyasi va fermentativ (oshqozon va ichak) faolligini oshiradi hamda oziq-ovqatninig so'rilishini yaxshilaydi.\n Bazal metabolizmni normallashtiradi, ortiqcha tana vaznini kamaytiradi va skelet mushaklaridagi yog 'miqdorini kamaytiradi. Jismoniy faoliyatga qarshilik chegarasini oshiradi, laktik atsidoz darajasini pasaytiradi va uzoq muddatli jismoniy zo'riqishlardan keyin ish faoliyatini tiklaydi.",
        '«ЛК-ТИН» улучшает белковый и жировой обмен, энергообеспечение тканей, повышает секрецию и ферментативную активность пищевари-тельных соков (желудочного и кишечного), улучшает усвоение пищи. Нормализует повышенный основной обмен, снижает избыточную массу тела и уменьшает содержание жира в скелетной мускулатуре. Повышает порог резистентности к физической нагрузке, уменьшает степень лактат- ацидоза и восстанавливает работоспособность после длительных физических нагрузок.',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "karnitin yetishmasligi yoki uning yo'qolishi ortishi bilan kechadigan kasalliklar",
            "chidamlilikni oshirish, charchoqni kamaytirish",
            "bolalarda o'sishning kechikishi va tana vaznining etishmasligida",
            "erta va yangi tug'ilgan chaqaloqlarni emizishda (vaznning kam ortishi)",
            "miya qon aylanishining buzilishi (INSULT)",
            "kardiyomiyopatiya, yurakning ishemik kasalligi (Stenokardiya, infarktdan keyingi holatlar)",
            "tana yog'ini kamaytirish va mushak massasini oshirish"


          ]
        },
        {
          id: 1,
          data: [
            'заболевания, сопровождающиеся недостатком карнитина или его повышенной потерей',
            'для повышения выносливости, снижения утомляемости',
            'при задержке роста и недостатке массы тела у детей',
            'при выхаживании недоношенных и новорожденных (низкая прибавка массы тела)',
            'нарушения мозгового кровообращения (инсульт)',
            'при кардиомиопатии, ИБС (стенокардия, постинфарктные состояния',
            'для редукции жировой массы тела и увеличения мышечной массы'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: "L-karnitin", Miqdori: '500 mg' },
            { Tarkibi: "Askorbin kislotasi (Vitamin C)", Miqdori: '50 mg' },
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'L-карнитин', Экстракт: '500 мг' },
            { Состав: 'Аскорбиновая кислота (витамин С)', Экстракт: '50 мг' }
          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali, ovqatlangandan 30 minutdan so'ng qabul qilinadi",
            "Kattalarga: 10ml siropdan kuniga 2-3 maxal",
            "13 yoshdan 18 yoshgacha bolalarga : 10ml siropdan kuniga 1-2 maxal",
            "3 yoshdan 12 yoshgacha bolalarga : 2-5ml siropdan kuniga 1 maxal",
            "3 yoshgacha bo'lgan bolalarga : davolovchi shifokor yordamida doza aniqlanadi",
            "Davolash kusdi : 1-2 oy. Mutaxassis maslahati tavsiya etiladi"
          ],

          contraindications: [
            "Preparat komponentlariga shaxsiy o'zlashtira olmaslik"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, через 30 минут после еды, запивая водой',
            'Взрослым: по 10 мл сиропа 2-3 раза в день',
            'Детям 13 - 18 лет: по 10 мл сиропа 1-2 раза в день',
            'Детям 3 - 12 лет: по 2 - 5 мл сиропа 1 раз в день',
            'Детям до 3 лет: дозировка определяется лечащим врачом',
            'Длительность приёма: 1 - 2 месяца. Рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'индивидуальная непереносимость к компонентам препарата'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },
    //#endregion

    //#region 3)
    // Product Rentrit  - update 2022.12.19
    // ID : 9
    {
      id: 9,
      router_name: 'Rentrit',
      name: ['Rentrit', 'Рентрит', 'Rentrit'],
      cost: '',
      title: [
        "Dorivor o'simliklardan tayorlangan diuretik vosita",
        'Диуретическое средство растительного происхождения',
        'Herbal diuretic'
      ],
      image: '/products/mainview/main_Rentrit.jpg',
      link: '/singleproduct',
      category: 'buyrak',
      type: ['Tabletka', 'Таблетка', 'Tablet'],
      type_index: 1,
      view: [
        'Chiqarilish shakli: Tabletka, 60 dona',
        'Форма выпуска: таблетки, по 60 штук в упаковке',
        'Form of release: Tablets, 60 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/mainview/main_Rentrit.jpg'
        }
      ],
      subtitle: [
        "Peshobni haydaydigan, spazmalitik, yallig'lanishga qarshi, gipotenziv, dezintoksikaltsiyalovchi va antioksidant ta'sirlarga ega. Qumlarni buyrakdan surib chiqarishni osonlashtiradi, peshob rN-ni kislotalik tomonga o'zgartiradi, mochevina va xloridlarni ajralishini yaxshilaydi.",
        'Обладает мочегонным, спазмолитическим, противовоспалительным, гипотензивным, антиоксидантным и дезинтоксикационным эффектами. Способствует безболезненному выведению конкрементов и песка из почек, изменяет pH мочи в кислую сторону, увеличивает выделение мочевины и хлоридов',
        'It has diuretic, antispasmodic, anti-inflammatory, hypotensive, antioxidant and detoxifying effects. Promotes painless removal of stones and sand from the kidneys, changes the pH of urine to the acid side, increases the release of urea and chlorides'
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Buyrak va siydik yo'llarining surunkali yallig'lanishi va shamollash kasalliklariga qarshi (pielit, pielonefrit, uretrit, stistit)",
            "Buyraktosh kasalligida (buyrak, siydik yo'li, siydik qopi tuzlarini haydashda)",
            "Siydik yo'llarida tosh hosil bo'lishini oldini olishda",
            'Buyraktosh kasalligidagi operatsiya jarayonidan keyingi umumiy muolajaga yordamchi dori sifatida.'
          ]
        },
        {
          id: 1,
          data: [
            'При хронических заболеваниях почек и мочевыводящих путей (пиелит, пиелонефрит, уретрит, цистит)',
            'При мочекаменной болезни (для выведения солей из почек, мочеточника и мочевого пузыря)',
            'Для профилактики камнеобразования в мочевыводящих путях',
            'В комплексной терапии, после операций, связанных с мочекаменной болезнью'
          ]
        },
        {
          id: 2,
          data: [
            'In chronic diseases of the kidneys and urinary tract(pyelitis, pyelonephritis, urethritis, cystitis)',
            'With urolithiasis (to remove salts from the kidneys, ureter and bladder)',
            'For the prevention of stone formation in the urinary tract',
            'In complex therapy, after operations associated with urolithiasis'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: "Ro'yan ekstrakti", Miqdori: '40mg' },
            { Tarkibi: 'Qizilpoycha ekstrakti', Miqdori: '40mg' },
            { Tarkibi: "Na'matak mevalari ekstrakti", Miqdori: '30mg' },
            { Tarkibi: "Qirqbo'g'in ekstrakti", Miqdori: '40mg' },
            { Tarkibi: 'Petrushka ekstrakti', Miqdori: '30mg' },
            { Tarkibi: "Makkajo'xori og'izchasi ekstrakti", Miqdori: '20mg' }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Марены красильной экстракт', Экстракт: '40мг' },
            { Состав: 'Зверобоя экстракт', Экстракт: '40мг' },
            { Состав: 'Плодов шиповника экстракт', Экстракт: '30мг' },
            { Состав: 'Хвоща экстракт', Экстракт: '40мг' },
            { Состав: 'Петрушки экстракт', Экстракт: '30мг' },
            { Состав: 'Кукурузных рылец экстракт', Экстракт: '20мг' }
          ]
        },
        //English language
        {
          id: 2,
          items: [
            { Composition: 'Rubia tinctorum extract', Extract: '40mg' },
            { Composition: 'Hypericum extract', Extract: '40mg' },
            { Composition: 'Dog-rose fruit extract', Extract: '30mg' },
            { Composition: 'Equisetum arvinse extract', Extract: '40mg' },
            { Composition: 'Carum Petroselinum extract', Extract: '30mg' },
            { Composition: 'Corn silk extract', Extract: '20mg' }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            'Ovqatlanishdan 20 daqiqa oldin',
            'Suv bilan ichilishi lozim',
            'Davolash kursi : 1-2 oy',
            'Kattalarga : 1 tabletkadan kuniga 3 maxal',
            '6 yoshdan katta bolalarga: 1 tabletkadan, kuniga 2 maxal'
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik",
            "Siydik yo'llari atrofidagi og'riqlarda preparatning qo'llanilishi tavsiya etilmaydi",
            "6 yoshgacha bo'lgan bolalarga qo'llanish tavsiya etilmaydi"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Прием внутрь',
            'За 20 мин до еды',
            'Необходимо запивать водой',
            'Длительность приёма : 1-2 месяца',
            'Взрослым : по 1 таблетке, 3 раза в день',
            'Детям с 6 лет : по 1 таблетке 2 раза в день'
          ],

          contraindications: [
            'Индивидуальная непереносимость к компонентам препарата',
            'Не рекомендуется применять препарат при острых болях в области мочевыводящих путей',
            'Десткий возраст до 6 лет'
          ]
        },
        {
          //English language
          id: 2,
          data: [
            'Прием внутрь',
            'За 20 мин до еды',
            'Необходимо запивать водой',
            'Длительность приёма : 1-2 месяца',
            'Взрослым : по 1 таблетке, 3 раза в день',
            'Детям с 6 лет : по 1 таблетке 2 раза в день'
          ],

          contraindications: [
            'Individual intolerance to the components of the drug',
            'It is not recommended to use the drug for acute pain in the urinary tract',
            'Children’s age up to 6 years'
          ]
        }
      ]
    },

    // Product MUKOLEKS
    // ID : 10
    {
      id: 10,
      router_name: 'Mukoleks',
      name: ['Mukoleks', 'МУКОЛЕКС', 'Mukoleks'],
      cost: '',
      title: [
        "Dorivor o'simliklardan tayorlangan yo'talga qarshi vosita.",
        'Отхаркивающее средство растительного происхождения.  ',
        'Expectorant of plant origin. Lungs easier with MUKOLEKS'
      ],
      image: '/products/1280x1010/Mukoleks_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: 'lung',
      type: ['Sirop', 'Сироп', 'Sirop'],
      type_index: 2,
      view: [
        'Chiqarilish shakli: sirop, flakonda 150 ml',
        'Форма выпуска:  сироп, 150 мл во флаконе, с мерной крышкой',
        'Release form: Syrup in a bottle of 150ml'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Mukoleks_Gray_1280x1010.jpg'
        },
        {
          id: 2,
          image: '/products/photostudio/b_Mukoleks_left.jpeg'
        },
        {
          id: 3,
          image: '/products/photostudio/b_Mukoleks_right.jpeg'
        },
        {
          id: 4,
          image: '/products/photostudio/b_Mukoleks_border.jpeg'
        },
        {
          id: 5,
          image: '/products/photostudio/b_Mukoleks_3.jpeg'
        }
      ],
      subtitle: [
        "Kombinatsiyalangan tarkibi tufayli, Mukoleks balg'am ko'chiruvchi, yallig'lanishga qarshi, og'riq qoldiruvchi, viruslarga qarshi tasirlarga ega, shilliq qavatni siqilish va shishishini, tomoq qichishini bartaraf etadi, shikastlangan shilliq qavatni bitishiga yordam beradi. Balg'amning qovushqoqligini pasaytiradi va uni ajralib chiqishiga yordam beradi, hamda yo'talni kamaytirish va bartaraf etishda yordam beradi. Nafas olishni yaxshilaydi",
        'Благодаря комбинированному составу, Муколекс обладает отхаркивающим, противовоспалительным, обезболивающим, противовирусным действиями, снимает спазм и отек слизистой, что облегчает и снимает зуд в горле, вызывающий судорожный кашель, способствует заживлению поврежденной слизистой. Способствует снижению вязкости мокроты и ускорению её эвакуации, также уменьшению и исчезновению кашля. Улучшает дыхание',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Yo'tal va balg'am paydo bo'lishi bilan kechuvchi nafas yo'llarining o'tkir va xronik yallig'lanish kasalliklarining kompleks davolashda",
            "Bronxitlar(xronik, obstruktiv, o'tkir)",
            'Faringit, laringit',
            "ORVI (ОРВИ) va balg'am ko'chishi og'irlashkanida",
            "SOO'K (СОЎК) - surunkali obstruktiv o'pka kasalligi",
            'Pnevmoniya',
            'Tuberkulez'
          ]
        },
        {
          id: 1,
          data: [
            'B комплексной терапии острых и хронических воспалительных заболеваний дыхательных путей',
            'Сопровождающихся кашлем и образованием мокроты',
            'Бронхиты – хронический, обструктивный, острый ',
            'Трахеобронхит, трахеит',
            'Фарингит, ларингит',
            'ОРВИ и осложнения с выделением мокроты',
            'ХОБЛ – хроническая обструктивная болезнь легких',
            'Пневмония',
            'Туберкулез'
          ]
        },
        {
          id: 2,
          data: [
            'B комплексной терапии острых и хронических воспалительных заболеваний дыхательных путей',
            'Сопровождающихся кашлем и образованием мокроты',
            'Бронхиты – хронический, обструктивный, острый ',
            'Трахеобронхит, трахеит',
            'Фарингит, ларингит',
            'ОРВИ и осложнения с выделением мокроты',
            'ХОБЛ – хроническая обструктивная болезнь легких',
            'Пневмония',
            'Туберкулез'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: 'Qizilmiya ildizi ektrakti', Miqdori: '30mg' },
            { Tarkibi: 'Moychechak ektrakti', Miqdori: '30mg' },
            { Tarkibi: 'Altey ektrakti', Miqdori: '20mg' },
            { Tarkibi: 'Tirnoqgul ektrakti', Miqdori: '30mg' },
            { Tarkibi: "Na'matak mevalari ektrakti", Miqdori: '30mg' },
            { Tarkibi: 'Mentol', Miqdori: '3mg' }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Экстракт корней Солодки', Экстракт: '30мг' },
            { Состав: 'Экстракт цветков Ромашки', Экстракт: '30мг' },
            { Состав: 'Экстракт травы Алтея', Экстракт: '20мг' },
            { Состав: 'Экстракт цветков Календулы', Экстракт: '30мг' },
            { Состав: 'Экстракт плодов Шиповника', Экстракт: '30мг' },
            { Состав: 'Ментол', Экстракт: '3мг' }
          ]
        },
        //English language
        {
          id: 2,
          items: [
            { Composition: 'Glycyrrhiza glabra extract', Extract: '30mg' },
            { Composition: 'Chamomillae flores extract', Extract: '30mg' },
            { Composition: 'Althaea extract', Extract: '20mg' },
            { Composition: 'Calendulae flores extract', Extract: '30mg' },
            { Composition: 'Rosehip fruit extract', Extract: '30mg' },
            { Composition: 'Menthol', Extract: '3mg' }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            'Ovqatlanishdan 15 daqiqa oldin',
            'Kattalarga va 16 yoshdan katta bolalarga: 10-15 ml dan, kuniga 2-3 maxal',
            '6 yoshdan 16 yoshgacha bolalarga: 5-10 ml, kuniga 2-3 maxal',
            '2 yoshdan 6 yoshgacha bolalarga: 2,5-5 ml, kuniga 1-2 maxal',
            'Davolash kursi : 2 - 4 hafta. Davolash kursini qaytarish mumkin.'
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik",
            "Boshqa dorilar bialn o'zaro tasiri: preparat spazmolitiklar ta'sirini kuchaytirishi mumkin",
            "Homiladorlik va emizish davrida qo'llanilishi: homiladorlik va emizish davrida berilishi haqida ma'lumot yo'q"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, за 15 мин до еды',
            'Взрослым и детям старше 16 лет: по 10 – 15 мл, 2 - 3 раза в день',
            'Детям с 6 до 16 лет: по 5 – 10 мл, 2 - 3 раза в день',
            'Детям с 2 до 6 лет: по 2,5 – 5 мл, 1 - 2 раза в день',
            'Длительность приёма: 2-4 недели, при необходимости возможен повторный курс с перерывом'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата',
            'Взаимодействие с другими лекарственными средствами: препарат усиливает действие спазмолитиков',
            'Применение при беременности и в период лактации: нет данных о применении в период беременности и лактации'
          ]
        },
        {
          //English language
          id: 2,
          data: [
            'Приём внутрь, за 15 мин до еды',
            'Взрослым и детям старше 16 лет: по 10 – 15 мл, 2 - 3 раза в день',
            'Детям с 6 до 16 лет: по 5 – 10 мл, 2 - 3 раза в день',
            'Детям с 2 до 6 лет: по 2,5 – 5 мл, 1 - 2 раза в день',
            'Длительность приёма: 2-4 недели, при необходимости возможен повторный курс с перерывом'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата',
            'Взаимодействие с другими лекарственными средствами: препарат усиливает действие спазмолитиков',
            'Применение при беременности и в период лактации: нет данных о применении в период беременности и лактации'
          ]
        }
      ]
    },

    // Product LB NORM
    // ID : 11
    {
      id: 11,
      router_name: 'LB_Norm_tablet',
      name: ['LB Norm', 'ЛБ НОРМ', 'LB Norm'],
      cost: '', //28,750
      title: [
        'Lactobacterium Bifidobacterium',
        'Lactobacterium Bifidobacterium',
        'Lactobacterium Bifidobacterium'
      ],
      image: '/products/1280x1010/LBNorm_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Tabletka', 'Таблетка', 'Tablet'],
      type_index: 1,
      view: [
        'Chiqarilish shakli: tabletka, 10 dona',
        'Форма выпуска: таблетки, по 10 штук в упаковке',
        'Release form: tablets, 10 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/LBNorm_Gray_1280x1010.jpg'
        },
        {
          id: 2,
          image: '/products/photostudio/b_LbNorm_2.jpeg'
        }
      ],
      subtitle: [
        'LB Norm ichak mikroflorasidagi foydali bakteriyalarni balansini yaxshilovchi majmuaviy preparat. LB Norm tarkibidagi bifido va laktobakteriyalar ichakning mikroflorasini normallashtiradi va oshqozon ichak trakti faoliyatini yaxshilaydi',
        'ЛБ норм является комбинированным препаратом для восполнения баланса полезных бактерий в кишечнике. Бифидо- и лактобактерии в составе препарата озволяют нормализовать микрофлору кишечника и тем самым улучшить деятельность желудочно-ишечного тракта и восстановлению естественного иммунитета',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            'Ichak mikroflorasini normallashtirish uchun tavsiya etiladi:',
            "- O'tkir ichak infeksiyalarida",
            '- Turli disbakterioz xolatlarida',
            '- Antibiotikoterapiya vaqtida yoki undan keyin',
            '- Ovqatdan zaxarlanish xolatlari',
            '- Diareyalarda',
            '- Allergik kasalliklarni kompleks davosi tarkibida',
            "Chaqaloqlar, kattalar, qariyalar, homiladorlar va laktatsiya davrida ham qo'llash tavsiya etiladi"
          ]
        },
        {
          id: 1,
          data: [
            'Рекомендуется принимать для нормализации микрофлоры кишечника при:',
            '- острых кишечных инфекциях',
            '- различных формах дисбактериоза',
            '- диареях',
            '- во время и/или после антибиотикотерапии',
            '- при пищевых отравлениях',
            '- при комплексном лечении аллергии',
            'Рекомендовано применение новорожденным, взрослым и лицам пожилого возраста, а также  при беременности и в период лактации'
          ]
        },
        {
          id: 2,
          data: [
            'Рекомендуется принимать для нормализации микрофлоры кишечника при:',
            '- острых кишечных инфекциях',
            '- различных формах дисбактериоза',
            '- диареях',
            '- во время и/или после антибиотикотерапии',
            '- при пищевых отравлениях',
            '- при комплексном лечении аллергии',
            'Рекомендовано применение новорожденным, взрослым и лицам пожилого возраста, а также  при беременности и в период лактации'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: 'Faol moddalar', Miqdori: 'antogonistik faol' },
            { Tarkibi: 'Bifidobacterium bifidum', Miqdori: '107 dan ortiqroq' },
            { Tarkibi: 'Lactobacillus fermentum', Miqdori: '107 dan ortiqroq' }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            {
              Состав: 'Aктивные компоненты',
              Экстракт: 'лиофильно высушенные комплекс пробиотических культур'
            },
            { Состав: 'Bifidobacterium bifidum', Экстракт: '107 и более' },
            { Состав: 'Lactobacillus fermentum', Экстракт: '107 и более' }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            'Ovqatlanishdan 30 daqiqa oldin',
            'Kattalarga va 7 yoshdan katta bolalarga: 1 kapsuladan kuniga 3-4 maxal',
            '2 yoshdan 7 yoshgacha bolalarga: 1 kapsuladan kuniga 2 maxal',
            '6 oylikdan 1 yoshgacha bolalarga: 1 kapsuladan kuniga 1 maxal',
            'Davolash kursi : 1 - 3 hafta. Davolash kursini qaytarish mumkin, mutaxassis maslahati tavsiya etiladi'
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik",
            "Maxsus ko'rsatmalar: laktoza yetishmovchiligi bor bemorlarda ehtiyotkorlik bilan qo'llash tavsiya etiladi"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, за 30 мин до еды',
            'Взрослым и детям старше 7 лет: по 1 капсуле 3 - 4 раза в день',
            'Детям с 2 до 7 лет: по 1 капсуле 2 раза в день',
            'Детям от 6 месяцев до 1 года: по 1 капсуле 1 раз в день',
            'Длительность приёма: 1 - 3 недели. Повторный курс возможен, рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата',
            'Особые указания: применять с осторожностью лицам с лактазной недостаточностью'
          ]
        },
        {
          //English language
          id: 2,
          data: [
            'Приём внутрь, за 30 мин до еды',
            'Взрослым и детям старше 7 лет: по 1 капсуле 3 - 4 раза в день',
            'Детям с 2 до 7 лет: по 1 капсуле 2 раза в день',
            'Детям от 6 месяцев до 1 года: по 1 капсуле 1 раз в день',
            'Длительность приёма: 1 - 3 недели. Повторный курс возможен,  рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата',
            'Особые указания: применять с осторожностью лицам с лактазной недостаточностью'
          ]
        }
      ]
    },

    // Product LB NORM SASHE
    // ID : 12
    {
      id: 12,
      router_name: 'LB_Norm_sashe',
      name: ['LB Norm', 'ЛБ НОРМ Саше', 'LB Norm'],
      cost: '',
      title: [
        'Lactobacterium  Bifidobacterium',
        'Lactobacterium  Bifidobacterium',
        'Lactobacterium  Bifidobacterium'
      ],
      image: '/products/1280x1010/LBNorm_Sashe_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Paket', 'Саше', 'Sachet'],
      type_index: 3,
      view: [
        'Chiqarilish shakli: Paket, 10 dona',
        'Форма выпуска: Саше, 10 штук',
        'Release form: Sachet, 10 pieces'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/LBNorm_Sashe_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        'LB Norm ichak mikroflorasidagi foydali bakteriyalarni balansini yaxshilovchi majmuaviy preparat. LB Norm tarkibidagi bifido va laktobakteriyalar ichakning mikroflorasini normallashtiradi va oshqozon ichak trakti faoliyatini yaxshilaydi',
        'ЛБ норм является комбинированным препаратом для восполнения баланса полезных бактерий в кишечнике. Бифидо- и лактобактерии в составе препарата озволяют нормализовать микрофлору кишечника и тем самым улучшить деятельность желудочно-ишечного тракта и восстановлению естественного иммунитета',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            'Ichak mikroflorasini normallashtirish uchun tavsiya etiladi:',
            "- O'tkir ichak infeksiyalarida",
            '- Turli disbakterioz xolatlarida',
            '- Antibiotikoterapiya vaqtida yoki undan keyin',
            '- Ovqatdan zaxarlanish xolatlari',
            '- Diareyalarda',
            '- Allergik kasalliklarni kompleks davosi tarkibida',
            "Chaqaloqlar, kattalar, qariyalar, homiladorlar va laktatsiya davrida ham qo'llash tavsiya etiladi"
          ]
        },
        {
          id: 1,
          data: [
            'Рекомендуется принимать для нормализации микрофлоры кишечника при:',
            '- острых кишечных инфекциях',
            '- различных формах дисбактериоза',
            '- диареях',
            '- во время и/или после антибиотикотерапии',
            '- при пищевых отравлениях',
            '- при комплексном лечении аллергии',
            'Рекомендовано применение новорожденным, взрослым и лицам пожилого возраста, а также  при беременности и в период лактации'
          ]
        },
        {
          id: 2,
          data: [
            'Рекомендуется принимать для нормализации микрофлоры кишечника при:',
            '- острых кишечных инфекциях',
            '- различных формах дисбактериоза',
            '- диареях',
            '- во время и/или после антибиотикотерапии',
            '- при пищевых отравлениях',
            '- при комплексном лечении аллергии',
            'Рекомендовано применение новорожденным, взрослым и лицам пожилого возраста, а также  при беременности и в период лактации'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: 'Faol moddalar', Miqdori: 'antogonistik faol' },
            { Tarkibi: 'Bifidobacterium bifidum', Miqdori: '107 va ortiqroq' },
            { Tarkibi: 'Lactobacillus fermentum', Miqdori: '107 va ortiqroq' }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            {
              Состав: 'Aктивные компоненты',
              Экстракт: 'лиофильно высушенные комплекс пробиотических культур'
            },
            { Состав: 'Bifidobacterium bifidum', Экстракт: '107 и более' },
            { Состав: 'Lactobacillus fermentum', Экстракт: '107 и более' }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            'Ovqatlanishdan 30 daqiqa oldin',
            'Kattalarga va 7 yoshdan katta bolalarga: 1 sashe paketik kuniga 3-4 maxal',
            '2 yoshdan 7 yoshgacha bolalarga: 1 sashe paketik kuniga 2 maxal',
            '6 oylikdan 1 yoshgacha bolalarga: 1 sashe paketik kuniga 1 maxal',
            "Tug'ilgandan 6 oygacha bo'lgan bolalarga: 1/2 sashe paketik kuniga 1 maxal, emizish vaqtida, ona suti yoki bolalar qo'shimcha ozuqasi bilan aralashtirib",
            'Davolash kursi : 1 - 3 hafta. Davolash kursini qaytarish mumkin, mutaxassis maslahati tavsiya etiladi'
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik",
            "Maxsus ko'rsatmalar: laktoza yetishmovchiligi bor bemorlarda ehtiyotkorlik bilan qo'llash tavsiya etiladi"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, за 30 мин до еды',
            'Взрослым и детям старше 7 лет: по 1 саше пакетику 3 - 4 раза в день',
            'Детям с 2 до 7 лет: по 1 саше пакетику 2 раза в день',
            'Детям от 6 месяцев до 1 года: по 1 саше пакетику 1 раз в день',
            'Детям от рождения до 6 месяцев: по 1/2 саше пакетика 1 раз в день, во время кормления, смешав с грудным молоком или продуктом детского питания',
            'Длительность приёма: 1 - 3 недели. Повторный курс возможен,  рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата',
            'Особые указания: применять с осторожностью лицам с лактазной недостаточностью'
          ]
        },
        {
          //English language
          id: 2,
          data: [
            'Приём внутрь, за 30 мин до еды',
            'Взрослым и детям старше 7 лет: по 1 капсуле 3 - 4 раза в день',
            'Детям с 2 до 7 лет: по 1 капсуле 2 раза в день',
            'Детям от 6 месяцев до 1 года: по 1 капсуле 1 раз в день',
            'Длительность приёма: 1 - 3 недели. Повторный курс возможен,  рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата',
            'Особые указания: применять с осторожностью лицам с лактазной недостаточностью'
          ]
        }
      ]
    },

    //#endregion

    //#region 4)
    // Product LIVANORM
    // ID : 13
    {
      id: 13,
      router_name: 'Livanorm',
      name: ['Livanorm', 'Ливанорм ', 'Livanorm'],
      cost: '',
      title: ['Jigar uchun ishonchli himoya', 'Надежная защита для печени', ''],
      image: '/products/1280x1010/Livanorm_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Kapsula', 'Капсула', 'Capsule'],
      type_index: 0,
      view: [
        'Chiqarilish shakli: Kapsula, 60 dona',
        'Форма выпуска: Капсула, по 60 штук в упаковке',
        'Release form: Capsule, 60 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Livanorm_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        "LIVANORM organizmdan ekzogen toksinlar va toksik metabolik mahsulotlarni olib tashlashga yordam beradi va shikastlangan gepatotsitlarning yangilanishini tezlashtiradi, gepatotsitlarda detoksifikatsiya jarayonlarini rag'batlantiradigan glutation zahiralarini saqlaydi",
        'ЛИВАНОРМ оказывает гепатопротекторное, желчегонное, мембраностабилизирующее, антиоксидантное действие. Стабилизирует мембраны гепатоцитов, уменьшая тем самым потерю трансаминаз, связывает свободные радикалы, ингибирует процессы перекисного окисления липидов, предупреждает разрушение клеточных структур. ЛИВАНОРМ помогает выводить из организма экзогенные токсины и токсичные продукты метаболизма, и ускоряет регенерацию поврежденных гепатоцитов, сохраняет запасы глутатиона, способствующего процессам детоксикации в гепатоцитах.',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Jigarninig toksik shikastlanishi (shu jumladan spirtli ichimliklar, giyohvand moddalar)",
            "Turli hix etiologiyalarning surunkali gepatiti",
            "Jigar sirrozi",
            "O't pufagi va o't yo'llari kasalliklarida (surunkali xoletsistit, xolangit, o't pufagi va o't yo'llarining diskinezi)",
            "Xoletsist ektomiyadan keyin tiklanish davri",
            "Dispeptik sindrom"
          ]
        },
        {
          id: 1,
          data: [
            'Показания к применению (в комплексной терапии):',
            '- Токсические поражения печени (в том числе алкогольные, лекарственные)',
            '- Хронические гепатиты различной этиологии',
            '- Цирроз печени',
            '- При заболеваниях желчного пузыря и желчевыводящих путей (хронический холецистит, холангит, дискинезии желчного пузыря и желчных путей)',
            '- Восстановительный период после холецистэктомии',
            '- Диспепсический синдром'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: 'Artishok ekstrakti', Miqdori: '100 mg' },
            { Tarkibi: "Sut qushqo'nmas ekstrakti", Miqdori: '100 mg' },
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Артишока экстракт', Экстракт: '100 мг' },
            { Состав: 'Расторопши экстракт', Экстракт: '100 мг' }
          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            "Ovqatlanishdan 20 daqiqa oldin, suv bilan",
            "12 yoshdan katta bolalar : kuniga 1-2 kapsuladan 2-3 maxal",
            "6 yoshdan 12 yoshgaca bolalar : 1 kapsuladan kuniga 1-2 maxal",
            "Davolash kursi : 2-3 hafta",
            "Davolash kursini qaytarish mumkin, mutaxassis maslaxati tavsiya etiladi"
          ],

          contraindications: [
            "Preparat komponentlariga shaxsiy o'zlashtira olmaslik",
            "Jigar, buyraklarning o'tkir kasalliklari. O't yo'llarining bloklanishi",
            "Yon ta'siri: kamdan-kam hollarda allergik reaktsiyalar, uzoq muddat foydalanish bilan dispeptik kasalliklar, epigastral mintaqada noqulaylik paydo bo'lishi mumkin"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, за 20 минут до еды, запивая стаканом воды',
            'Детям 6 - 12 лет: по 1 капсуле 1 - 2 раза в день',
            'Длительность приёма: 2 - 3 недели. Повторный курс возможен, рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'Индивидуальная непереносимость к компонентам препарата',
            'Острые заболевания печени, почек. Закупорка желчевыводящих путей',
            'Побочные эффекты: в редких случаях могут появиться аллергические реакции, диспепсические расстройства при длительном применении, дискомфорт в подложечной области'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    // Product MAGNEPERIT
    // ID : 14
    {
      id: 14,
      router_name: 'Magnepirit',
      name: ['Magnepirit', 'Магнепирит', 'Magnepirit'],
      cost: '',
      title: [
        'Magniy va B6 vitamini yetishmovchiligining oldini olish va davolash uchun',
        'Профилактика и лечение дефицита магния и витамина В6', ''],
      image: '/products/1280x1010/Magneperit_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Tabletka', 'Таблетка', 'Tablet'],
      type_index: 1,
      view: [
        'Chiqarilish shakli: tabletka, 60 dona',
        'Форма выпуска: таблетки, по 60 штук в упаковке',
        'Release form: tablets, 60 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Magneperit_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        "Magniy tarkibidagi Magnepirid inson organizimida hujayra o'tkazuvchanligini va nerv-mushaklarni tartibga solishda ishitirok etadigan muhim element hisoblanadi hamda energiya saqlash va ishlatish, oqsillar va nuklein kisolatalarida ham ishtirok etadi. Magniy nerv-mushak qo'zg'alish jarayonini to'sadi va miyokardning tartibga solish jarayonida ishtirok etadi. Tanadagi magniy yetishmovchiligi quyidagi holarlarda namoyon bo'ladi - mushaklarning kuchsizligi, asabiylashish, uyqu buzilishi, yurak ritmining buzilishi, refleksli qo'zg'aluvchanlikning kuchayishi, tremor, tetaniya, ataksiya. \nMAGNEPIRIDE shuningdek, piridoksin gidroxloridi (B6 vitamini) o'z ichiga oladi. Markaziy va periferik asab tizimining normal ishlashi uchun muhim rol o'ynaydi. magniyning oshqozon-ichak traktidan so'rilishini yaxshilaydi va hujayralarga kirib borishini ta'minlaydi.",
        'Магний в своем составе МАГНЕПИРИД является важным элементом для организма, участвующий в регуляции проницаемости клеток и нервно-мышечной проводимости, также участвует в хранении и утилизации энергии, синтезе белка и нуклеиновых кислот. Магний угнетает процесс нервно-мышечного возбуждения, участвует в регуляции в сократительной функции миокарда. Дефицит магния в организме проявляется мышечной слабостью, раздражительностью, расстройствами сна, нарушениями ритма сердца, повышением рефлекторной возбудимости,тремором, тетанией, атаксией. \n МАГНЕПИРИД также имеет в составе пиридоксина гидрохлорид (витамин В6), который играет важную роль для нормального функционирования центральной и периферической нервной системы. улучшает всасывание магния из желудочно-кишечного тракта и обеспечивает проникновение в клетки.',
        ''],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            'Belgilangan magniy etishmovchiligi',
            'Asabiylashish kuchayishi',
            'Yengil uyqu buzilishi',
            'Oshqozon - ichak spazmalari ',
            'Charchoq kuchayishi',
            "Mushaklarda og'riq va spazmalar",
            'Yurak qisqarishlarining tezlashuvi (Taxikardiya)',
            'Mushaklarda sanchish hissi'

          ]
        },
        {
          id: 1,
          data: [
            'Установленный дефицит магния',
            'изолированный или связанный с другими дефицитными состояниями',
            'сопровождающийся такими симптомами, как повышенная раздражительность',
            'нарушения сна легкой степени, желудочно-кишечные спазмы,',
            'желудочно-кишечные спазмы, повышенная утомляемость, боли и спазмы мышц',
            'учащенное сердцебиение, ощущение покалывания в мышцах'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: "Magniy laktat", Miqdori: "470 mg" },
            { Tarkibi: "Piridoksin gidroxloridi (B6 vitamini)", Miqdori: "5 mg" }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Магния лактат', Экстракт: '470 мг' },
            { Состав: 'Пиридоксина гидрохлорид (витамин В6)', Экстракт: '5 мг' },

          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi, ovqatlanish vaqtida",
            "Kattalar uchun : 1 tabletkadan kuniga 3 maxal",
            "6 yoshdan katta bo'lgan bolalar: 1 ta tabletkadan kuniga 2 maxal",
            'Davolash kursi : 1 - 3 oy'
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik, giperkalsiemiya, og'ir buyrak kasalliklari"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, во время еды',
            'Взрослым: по 1 таблетке 3 раза в день',
            'Детям с 6 лет: по 1 таблетке 2 раза в день',
            'Длительность приёма: 1 - 3 месяца'
          ],

          contraindications: [
            'индивидуальная непереносимость к компонентам препарата, тяжелая почечная недостаточность'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    // Product Kalsiy D3 CS
    // ID : 15
    {
      id: 15,
      router_name: 'Kalsiy_D3_CS',
      name: ['Kalsiy D3 CS', 'Кальций Д3 ЦС', 'Calcium D3 CS'],
      cost: '', //42,550
      title: ['Immunitetni mustahkamlash va osteoporoz, raxitni oldini olish va kompleks davolash uchun', 'Для укрепления иммунитета, профилактики и в комплексном лечении остеопороза, рахита', ''],
      image: '/products/1280x1010/Kalsiy_D3_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Tabletka', 'Таблетка', 'Tablet'],
      type_index: 1,
      view: [
        'Chiqarilish shakli: tabletka, 60 dona',
        'Форма выпуска: таблетки, по 60 штук в упаковке',
        'Форма выпуска: таблетки, по 60 штук в упаковке'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Kalsiy_D3_Gray_1280x1010.jpg'
        },
        {
          id: 2,
          image: '/products/photostudio/b_KalciyCS_left.jpeg'
        },
        {
          id: 3,
          image: '/products/photostudio/b_KalciyCS_right.jpeg'
        },
        {
          id: 4,
          image: '/products/photostudio/b_KalciyCS_2.jpeg'
        }
      ],
      subtitle: [
        "Kalsiy D3 CS organizmdagi kaltsiy, vitamin D3, vitamin C, rux, selen yetishmasligini oldini oladi, immunitetni himoya qilish reaktsiyalarida ishtirok etadi, shamollash va yuqumli kasalliklarga chidamliligini oshiradi va metabolik ta'sir ko'rsatadi. Tanani oksidlovchi stressdan himoya qilishda ishtirok etadi, rezorbsiyani kamaytiradi va suyak zichligini oshiradi, tishlarning mineralizatsiyasini ta'minlaydi",
        '"Кальций Д3 ЦС" восполняет дефицит кальций, витамина Д3, витамина С, цинка, селена в организме, участвует в реакциях иммунной защиты, усиливает сопротивляемость к простудным и ифекционным заболеваниям,  оказывает метаболическое дейтвие. Участвует в защите организма от окислительного стресса, снижает резорбицию и повышает плотность костной ткани, обеспечивает минерализацию зубов',
        '"Кальций Д3 ЦС" восполняет дефицит кальций, витамина Д3, витамина С, цинка, селена в организме, участвует в реакциях иммунной защиты, усиливает сопротивляемость к простудным и ифекционным заболеваниям,  оказывает метаболическое дейтвие. Участвует в защите организма от окислительного стресса, снижает резорбицию и повышает плотность костной ткани, обеспечивает минерализацию зубов'
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Ostreopoz, Suyak sinishi, Raxit",
            "O'sish to'xtashi. Past bo'ylikda",
            "Shamollash kasaliklariga moyillik tug'ilganda",
            "Stress, Jizzakilik",
            "Soch to'kilishi. Mo'rt tirnoqlar",
            "Kaltsiy, D3 vitamini, S vitamini, Sink, Selen yetishmovchiligini davolashda"
          ]
        },
        {
          id: 1,
          data: [
            'Остеопороз, Костные переломы, Рахит',
            'Задержка в росте. В период интенсивного роста',
            'Склонности к простудным заболеваниям',
            'Стресс, Раздражительность',
            'Выпадение волос. Ломкость ногтей',
            'В профилактие лечении дефецита кальция, витамин Д3, витамин С, цинка, селена'
          ]
        },
        {
          id: 2,
          data: []
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: 'Kaltsiy karbonat', Miqdori: '800 mg' },
            { Tarkibi: 'Kolekalsiferol (Vitamin D3)', Miqdori: '400 ME' },
            { Tarkibi: 'Sink laktat', Miqdori: '25 mg' },
            { Tarkibi: 'Askorbin kislotasi (Vitamin С)', Miqdori: '50 mg' },
            { Tarkibi: 'Selen', Miqdori: '40 mkg' }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Кальция карбонат', Экстракт: '800 мг' },
            { Состав: 'Колекальциферол (витамин Д3)', Экстракт: '400 ME' },
            { Состав: 'Цинк лактат', Экстракт: '25 мг' },
            { Состав: 'Аскорбиновая кислота (витамин С)', Экстракт: '50 мг' },
            { Состав: 'Селен', Экстракт: '40 мкг' }
          ]
        },
        //English language
        {
          id: 2,
          items: [
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            "Ovqatlanish vaqtida, tabletkani chaynash yoki shimish lozim",
            "Kattalar va 12 yoshdan oshgan o'smirlar: 1 tabletkadan kuniga 2 maxal",
            "3-12 yoshgacha bo'lgan bolalar: 0,5-1 ta tabletkadan kuniga 1 maxal",
            "Davolash kursi : 1 - 2 oy",
            "Zarurat tug'ilganda, 30 kunlik tanaffusdan so'ng ikkinchi kurs boshlash mumkin",
            "Mutahassis maslahati tavsiya etiladi"
          ],

          contraindications: [
            "Kamdan kam hollarda allergik reaktsiyalar, yengil dispepsiya (meteorizm, ko'ngil aynishi, ich qotishi yoki diareya)",
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik, giperkalsiemiya, og'ir buyrak kasalliklari"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, во время еды. Таблетки следует разжёвывать или рассасывать',
            'Взрослым и детям старше 12 лет: по 1 таблетке 2  раза в день',
            'Детям с 3 лет до 12 лет: по 0.5 - 1 теблетке 1 раз в день',
            'Длительность приёма: 1-2 месяца',
            'При необходимости возможен повторный курс с перерывом 30 дней',
            'Рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'В редких случаях, аллергические реакции, слабо выраженные явления диспепсии (метеоризм, тошнота, запор или диарея)',
            'Индивидуальная непереносимость к компонентам препарата, гиперкальциемия, тяжелые заболевания почек'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    // Product KALSIY D3 FORTE
    // ID : 16
    {
      id: 16,
      router_name: 'Kalsiy_D3_Forte',
      name: ['Kalsiy D3 FORTE', 'Кальций Д3 ФОРТЕ', 'Calcium D3 Forte'],
      cost: '',
      title: [
        'Kalsiy, vitamin D3 va mikroelementlarining tabiiy manbai',
        'Источник кальция, микроэлементов и витамина D3',
        'Source of calcium, trace elements and vitamin D3'
      ],
      image: '/products/1280x1010/Kalsiy_Forte_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: 'calcium',
      type: ['Tabletka', 'Таблетка', 'Tablet'],
      type_index: 1,
      view: [
        'Chiqarilish shakli: tabletka, 60 dona',
        'Форма выпуска: таблетки, по 60 штук в упаковке',
        'Form of release: tablets, 60 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Kalsiy_Forte_Gray_1280x1010.jpg'
        },
        {
          id: 2,
          image: '/products/photostudio/b_KalsiyForte_left.jpeg'
        },
        {
          id: 3,
          image: '/products/photostudio/b_KalsiyForte_right.jpeg'
        },
        {
          id: 4,
          image: '/products/photostudio/b_KalsiyForte_front_2.jpeg'
        },
        {
          id: 5,
          image: '/products/photostudio/b_KalsiyForte_front_3.jpeg'
        }
      ],
      subtitle: [
        "Kalsiy va vitamin D tanqisligini to'ldiruvchi kalsiy va vitamin D ni faol shaklidan iborat majmuaviy preparat. Preparat organizmda kalsiy va fosfor almashinuvini boshqaradi. Organizmda kalsiy va vitamin D3 tanqisligini to'ldirib, suyak to'qimasi va rezobsiyasini(so'rilishini) kamaytiradi va zichligini oshiradi, tishlar minerallanishini ta'minlaydi. Kalsiy nerv o'tkazuvchanligi boshqarilishida, mushaklar qisqarishida, gormonlar ishlab chiqarilishida ishtrok etadi, shuningdek, qon ivish sistemasi komponenti hisoblanadi.",
        'Комплексный препарат из активной формы кальция и витамина D, восполняющий дефицит кальция и витамина D в организме. Препарат участвует в регуляции обмена кальция и фосфора, восполняя дефицит кальция и витамина D в организме, снижает резорбцию и повышает плотность костной ткани, обеспечивает минерализацию зубов. Кальций участвует в проводимости нервных импульсов, мышечном сокращении, образовании гормонов,также является одним из факторов свёртывания крови.',
        'A complex preparation of the active form of calcium and vitamin D, which compensates for the deficiency of calcium and vitamin D in the body. The drug is involved in the regulation of calcium and phosphorus metabolism, replenishing calcium and vitamin D deficiency in the body, reduces resorption and increases bone density, and provides mineralization of teeth. Calcium is involved in the conduction of nerve impulses, muscle contraction, the formation of hormones, and is also one of the blood coagulation factors'
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            'Ostreopoz, Suyak sinishi',
            'Raxit. Oyoqlar qiyshiqligida',
            "O'sish to'xtashida, past bo'ylikda",
            'Tishlar kech chiqishida',
            'Stress, jizzakilikda',
            "Kamsochlik va tirnoqlardagi oq dog'larda",
            'Kalsiy, fosfor, vitamin D3 tanqisligini oldini olish va davolashda'
          ]
        },
        {
          id: 1,
          data: [
            'Остеопороз, Костные переломы',
            'Рахит',
            'Задержка в росте. В период интенсивного роста',
            'Позднее прорезывание зубов',
            'Стресс, Раздражительность',
            'Выпадение волос. Ломкость ногтей',
            'В профилактие и лечении дефецита кальция, витамин D3'
          ]
        },
        {
          id: 2,
          data: [
            'Osteoporosis, Bone fractures',
            'Rickets',
            'Stunted growth. During the period of intensive growth',
            'Late teething',
            'Stress, Irritability',
            'Hair loss, Brittle nails',
            'ВIn the prevention and treatment of calcium deficiency, vitamin D3'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: 'Kalsiy korbonat', Miqdori: '800mg' },
            { Tarkibi: 'Kolekalsiferol (Vitamin D3)', Miqdori: '200XB' },
            {
              Tarkibi:
                "Makro va mikro elementlar(rux, ftor, mis, kremniy, temir marganes, molibden, fosfor, oltingugurt, natriy, kaliy, bo'r, kobalt va boshqalar)",
              Miqdori: ''
            }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Кальция карбонат', Экстракт: '800мг' },
            { Состав: 'Колекальциферол (витамин Д3)', Экстракт: '200ME' },
            {
              Состав:
                'макро-микроэлементы (фосфор, цинк, фтор, медь, кремний, железо, марганец, молибден, сера, натрий, калий, бор, кобальт и др.)',
              Экстракт: ''
            }
          ]
        },
        //English language
        {
          id: 2,
          items: [
            { Composition: 'Calcium carbonate', Extract: '800mg' },
            { Composition: 'Colecalciferol (vitamin D3)', Extract: '200 IU' },
            {
              Composition:
                'phosphorus, zinc, fluorine, copper, silicon, iron, manganese, molybdenum, sulfur, sodium, potassium, boron, cobalt, etc.)',
              Extract: ''
            }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            'Ovqatlanish vaqtida, tabletkani chaynash yoki shimish lozim',
            "Kattalar va 12 yoshdan oshgan o'smirlar: 1 tabletkadan kuniga 2 maxal",
            "3-12 yoshgacha bo'lgan bolalar: 0,5-1 ta tabletkadan kuniga 1 maxal",
            'Davolash kursi : 1 - 2 oy'
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik, giperkalsiemiya, og'ir buyrak kasalliklari"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, во время еды. Таблетки следует разжёвывать или рассасывать',
            'Взрослым и подросткам старше 12 лет: по 1 таблетке 2  раза в день',
            'Детям с 3 лет до 12 лет: по 0.5 - 1 теблетке 1 раз в день',
            'Курс лечения: 1-2 месяца'
          ],

          contraindications: [
            'Тяжелые заболевания почек, индивидуальная непереносимость к компонентам препарата, гиперкальциемия.'
          ]
        },
        {
          //Russian language
          id: 2,
          data: [
            'Ingestion, during meals, tablets should be chewed or dissolve',
            'Adults and adolescents over 12 years of age: 1 tablet 2 times a day',
            'Children from 3 to 12 years: 0.5 - 1 tablet 1 time per day',
            'Course of treatment: 1 - 2 months'
          ],

          contraindications: [
            'Severe kidney disease, individual intolerance to the components of the drug, hypercalcemia'
          ]
        }
      ]
    },
    //#endregion

    //#region 5)
    // Product PLANTOFER
    // ID : 19
    {
      id: 19,
      router_name: 'Plantofer',
      name: ['Plantofer', 'Плантофер', 'Plantofer'],
      cost: '', //39,100
      title: [
        'Gemoglobin darajasini oshirish va anemiyaning oldini olish',
        'Для повышения уровня гемоглобина и профилактики анемии',
        'To increase hemoglobin levels and prevent anemia'
      ],
      image: '/products/1280x1010/Plantofer_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Sirop', 'Сироп', 'Syrup'],
      type_index: 2,
      view: [
        'Chiqarilish shakli: sirop, flakonda 200 ml',
        'Форма выпуска: Сироп во флаконе по 200 мл, с мерной ложкой',
        'Release form: Syrup in a bottle of 200ml with a measuring spoon'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Plantofer_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        '',
        'Плантофер восполняет дефицит микроэлемента железа в организме, входящего в состав гемоглобина, миоглобина и многих ферментов, тем самым восстанавливает нормальный уровень гемоглобина в крови. Плантофер восполняет дефицит витаминов группы «B» в том числе фолиевой кислоты (витамин B9), цианокобаламина (витамин B12), которые необходимы для формирования эритроцитов и синтеза аминокислот. Способствует нормализации эритропоэза. При лечении препаратом Плантофер наблюдается постепенное уменьшение клинических симптомов, таких как - слабость, утомляемость, головокружение, тахикардия, сухость и болезненность кожных покровов. Укрепляет иммунитет.',
        'Syrup based on medicinal plants for the prevention and treatment of anemia of various etiologies'
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [

          ]
        },
        {
          id: 1,
          data: [
            'Сироп на основе лекарственных растений, для профилактики и лечения анемии различной этиологии',
            'Железодефицитная анемия',
            'B9 дефицитная анемия',
            'B12 дефицитная анемия',
            'Нарушение усвояемости железа в организме'
          ]
        },
        {
          id: 2,
          data: [
            'Iron-deficiency anemia',
            'B9 deficiency anemia',
            'B12 deficiency anemia',
            'Violation of the absorption of iron in the body'
          ]
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            {
              Tarkibi: "O'simlik ektraktlari",
              Miqdori:
                "Qora zira (Nigella sativa) \\n    - Zirk (Berberis)<br/>     - Za'faron (Crocus)"
            },
            {
              Tarkibi: 'Mikroelementlar',
              Miqdori:
                'Fe (Temir) - 2000 mkg<br/> Zn (Sink) - 2 mg<br/> Se (Selen) - 3 mg<br/>  Mg (Magniy) - 2 mg<br/>  Ca (Kaltsiy) - 14 mg<br/>  K (Kaliy) - 16 mg<br/>  Cu (Mis) - 3 mkg<br/>  P (Fosfor) - 8 mg'
            },
            {
              Tarkibi: 'Vitaminlar',
              Miqdori:
                'B1 - 5 mkg<br/>  B4 - 6 mg<br/> B9 - 2 mg<br/>	E - 8 mcg<br/>  β-каротин - 4 mg<br/>  B2 - 4 mkg<br/>	B6 - 6 mkg<br/>	 PP - 8 mg<br/>	K - 16 mg'
            }
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            {
              Состав: 'Экстракты растений',
              Экстракт:
                'Черный тмин (Nigella sativa)<br/>    - Барбарис (Berberis)<br/>     - Шафран (Crocus)'
            },
            {
              Состав: 'Микроэлементы',
              Экстракт:
                'Fe (железо) - 2000 мкг<br/> Zn (цинк) - 2 мг<br/> Se (селен) - 3 мг<br/>  Mg (магний) - 2 мг<br/>  Ca (кальций) - 14 мг<br/>  K (калий) - 16 мг<br/>  Cu (медь) - 3 мкг<br/>  P (фосфор) - 8 мг'
            },
            {
              Состав: 'Витамины',
              Экстракт:
                'B1 - 5 мкг<br/>  B4 - 6 мг<br/> B9 - 2 мг<br/>	E - 8 мкг<br/>  β-каротин - 4 мг<br/>  B2 - 4 мкг<br/>	B6 - 6 мкг<br/>	 PP - 8 мг<br/>	K - 16 мг  '
            }
          ]
        },
        //English language
        {
          id: 1,
          items: [
            {
              Composition: 'Plant extracts ',
              Extract: 'Nigella sativa<br/>    - Berberis<br/>     - Crocus'
            },
            {
              Composition: 'Microelements',
              Extract:
                'Fe - 2000 mcg<br/> Zn - 2 mg<br/> Se - 3 mg<br/>  Mg - 2 mg<br/>  Ca - 14 mg<br/>  K - 16 mg<br/>  Cu - 3 mcg<br/>  P - 8 mg'
            },
            {
              Composition: 'Vitamins',
              Extract:
                'B1 - 5 mcg<br/>  B4 - 6 mg<br/> B9 - 2 mg<br/>	E - 8 mcg<br/>  β-каротин - 4 mg<br/>  B2 - 4 mcg<br/>	B6 - 6 mcg<br/>	 PP - 8 mg<br/>	K - 16 mg'
            }
          ]
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            "Ovqatlanish oldidan 20-30 minut oldin yoki ovqatdan 60 minutdan so'ng",
            'Kattalar : 5-10 ml dan (1 - 2 choy qoshiq) kuniga 2-3 maxal',
            'Bolalar: 2,5-5 ml dan (0,5 - 1 choy qoshiq) kuniga 2 maxal',
            "Davolash kursi anemiyaning og'irligiga qarab 1 oydan 2 oygacha"
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik",
            "Maxsus ko'rsatmalar: preparatni qabul qilishda sho'r va achchiq ovqatlarni, shuningdek gazlangan ichimliklarni qabul qilish tavsiya etilmaydi",
            "Boshqa dorilar bilan o'zaro ta'siri: polivitaminlar bilan birgalikda qabul qilish tavsiya etilmaydi",
            "Homiladorlik va emizish davrida qo'llanilishi: homiladorlik va emizish davrida berilishi haqida ma'lumot yo'q"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, а 20-30 мин до еды или через 60 мин после еды',
            'Взрослым 5-10 мл (1-2 мерной ложки) 2-3 раза в день',
            'Детям 2,5-5 мл (0,5-1 мерной ложки) 2 раза в день',
            'Курс лечения составляет от 1 до 2 месяцев исходя от степени выраженности анемии'
          ],

          contraindications: [
            'Индивидуальная непереносимость компонентов препарата',
            'Особые указания: не рекомендуется приём солёной и острой пищи, а также газированных напитков во время приёма препарата',
            'Взаимодействие с другими лекарственными средствами: не рекомендуется одновременный приём поливитаминных комплексов',
            'Применение при беременности и в период лактации: нет данных о применении в период беременности и лактации'
          ]
        },
        {
          //English language
          id: 2,
          data: [
            'Ingestion, 20-30 minutes before a meal or 60 minutes after a meal',
            'Adults 5-10 ml (1-2 scoops) 2-3 times a day',
            'Children 2.5-5 ml (0.5-1 scoop) 2 times a day',
            'The course of treatment is from 1 to 2 months, depending on the severity of anemia'
          ],

          contraindications: [
            'Individual intolerance to the components of the drug',
            'Special instructions: it is not recommended to take salty and spicy foods, as well as carbonated drinks while taking the drug',
            'Interaction with other medicinal products: simultaneous intake of multivitamin complexes is not recommended',
            'Use during pregnancy and lactation: There are no data on the use during pregnancy and lactation'
          ]
        }
      ]
    },

    // Product EKSTRAFER
    // ID : 17
    {
      id: 17,
      router_name: 'Ekstrafer_capsule',
      name: ['Ekstrafer', 'Экстрафер', 'Ekstrafer'],
      cost: '',
      title: ['Gemoglobin darajasini oshirish va anemiyaning oldini olish uchun', 'Для повышения уровня гемоглобина и профилактики анемии', ''],
      image: '/products/1280x1010/Ekstrafer_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Kapsula', 'Капсула', 'Capsule'],
      type_index: 0,
      view: [
        'Chiqarilish shakli: Kapsula, 30 dona',
        'Форма выпуска: Капсула, по 30 штук в упаковке',
        'Release form: Capsule, 30 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Ekstrafer_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        "EXTRAFER tanadagi temir mikroelementlarining yetishmasligini qoplaydi, gemoglobin, miyoglobin va ko'plab fermentlarning bir qismi bo'lgan qondagi gemoglobinning normal darajasini tiklaydi.\n EXTRAFER qizil qon hujayralari shakllanishi va aminokislotalarning sintezi uchun zarur bo'lgan foliy kislotasi (vitamin B9), siyanokobalamin (vitamin B12), askorbin kislotasi (vitamin C) yetishmasligini qoplaydi. \n EXTRAFER eritropoezning normallashishiga yordam beradi, anemiyaning klinik belgilari - zaiflik, charchoq, bosh aylanishi, taxikardiya, terining quruqligi va og'rig'i namoyon bo'lishini kamaytiradi.",
        "«ЭКСТРАФЕР» восполняет дефицит микроэлемента железа в организме, входящего в состав гемоглобина, миоглобина и многих ферментов, тем самым восстанавливает нормальный уровень гемоглобина в крови. \n «ЭКСТРАФЕР» восполняет дефицит фолиевой кислоты (витамин B9), цианокобаламина (витамин B12), аскорбиновой кислоты (витамина С), которые необходимы для формирования эритроцитов и синтеза аминокислот. \n «ЭКСТРАФЕР»  способствует нормализации эритропоэза, уменьшает проявление клинических симптомов анемии - слабость, утомляемость, головокружение, тахикардия, сухость и болезненность кожных покровов",
        ''],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            'Temir tanqisligi anemiyasi',
            'B9 tanqisligi kamqonligi',
            'B12 tanqisligi kamqonligi',
            "Organizmdagi temirning so'rilishini buzilishi"
          ]
        },
        {
          id: 1,
          data: [
            'Железодефицитная анемия',
            'B9 дефицитная анемия',
            'B12 дефицитная анемия',
            'Нарушение усвояемости железа в организме'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          title: '',
          items: [
            { Tarkibi: "Temir sulfat", Miqdori: "40 mg" },
            { Tarkibi: "Askorbin kislotasi (vitamin C)", Miqdori: "30 mg" },
            { Tarkibi: "Askorbin kislotasi (vitamin C)", Miqdori: "7,5 mkg" },
            { Tarkibi: "Folik kislota (vitamin B9)", Miqdori: "750 mkg" },

          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Железа сульфат', Экстракт: '40 мг' },
            { Состав: 'Аскорбиновая кислота (витамин С)', Экстракт: '30 мг' },
            { Состав: 'Цианокобаламин (витамин В12)', Экстракт: '7,5 мкг' },
            { Состав: 'Фолиевая кислота (витамин В9)', Экстракт: ' 750 мкг' },
          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Suv bilan ichga qabul qilish orqali qo'llaniladi",
            "Ovqatlanishdan 20 minut so'ng",
            "Kattalar : 1 kapsuladan kuniga 1-2 maxal",
            "12-18 yoshgacha bo'lgan bolalar : 1kapsuladan kuniga 1 maxal",
            "6 yoshgacha bo'lgan bolalar : davolovchi mutahassis tomonidan belgilanadi",
            "Davolash kursi : 1 - 2 oy. Mutahassis maslahati tavsiya etiladi"
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, через 20 минут после еды, запивая стаканом воды',
            'Взрослым: по 1 капсуле 1 - 2 раза в день',
            'Детям 12 - 18 лет: по 1 капсуле 1 раз в день',
            'Детям до 6 лет: дозировка определяется лечащим врачом',
            'Длительность курса: 1 - 2 месяца. Рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'индивидуальная непереносимость к компонентам препарата'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    // Product EKSTRAFER SIROP
    // ID : 18
    {
      id: 18,
      router_name: 'Ekstrafer_sirop',
      name: ['Ekstrafer', 'Экстрафер', 'Ekstrafer'],
      cost: '',
      title: ['Gemoglobin darajasini oshirish va anemiyaning oldini olish uchun', 'Для повышения уровня гемоглобина и профилактики анемии', ''],
      image: '/products/1280x1010/Ekstrafer_Sirop_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Sirop', 'Сироп', 'Sirop'],
      type_index: 2,
      view: [
        'Chiqarilish shakli: Sirop, 150ml',
        'Форма выпуска: Сироп, 150мл',
        'Release form: Sirop, 150ml'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Ekstrafer_Sirop_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        "EXTRAFER tanadagi temir mikroelementlarining yetishmasligini qoplaydi, gemoglobin, miyoglobin va ko'plab fermentlarning bir qismi bo'lgan qondagi gemoglobinning normal darajasini tiklaydi.\n EXTRAFER qizil qon hujayralari shakllanishi va aminokislotalarning sintezi uchun zarur bo'lgan foliy kislotasi (vitamin B9), siyanokobalamin (vitamin B12), askorbin kislotasi (vitamin C) yetishmasligini qoplaydi. \n EXTRAFER eritropoezning normallashishiga yordam beradi, anemiyaning klinik belgilari - zaiflik, charchoq, bosh aylanishi, taxikardiya, terining quruqligi va og'rig'i namoyon bo'lishini kamaytiradi",
        "«ЭКСТРАФЕР» восполняет дефицит микроэлемента железа в организме, входящего в состав гемоглобина, миоглобина и многих ферментов, тем самым восстанавливает нормальный уровень гемоглобина в крови. \n «ЭКСТРАФЕР» восполняет дефицит фолиевой кислоты (витамин B9), цианокобаламина (витамин B12), аскорбиновой кислоты (витамина С), которые необходимы для формирования эритроцитов и синтеза аминокислот. \n «ЭКСТРАФЕР»  способствует нормализации эритропоэза, уменьшает проявление клинических симптомов анемии - слабость, утомляемость, головокружение, тахикардия, сухость и болезненность кожных покровов",
        ''],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            'Temir tanqisligi anemiyasi',
            'B9 tanqisligi kamqonligi',
            'B12 tanqisligi kamqonligi',
            "Organizmdagi temirning so'rilishini buzilishi"
          ]
        },
        {
          id: 1,
          data: [
            'Железодефицитная анемия',
            'B9 дефицитная анемия',
            'B12 дефицитная анемия',
            'Нарушение усвояемости железа в организме'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: "Temir sulfat", Miqdori: "40 mg" },
            { Tarkibi: "Askorbin kislotasi (vitamin C)", Miqdori: "30 mg" },
            { Tarkibi: "Askorbin kislotasi (vitamin C)", Miqdori: "7,5 mkg" },
            { Tarkibi: "Folik kislota (vitamin B9)", Miqdori: "750 mkg" },
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Железа сульфат', Экстракт: '40 мг' },
            { Состав: 'Аскорбиновая кислота (витамин С)', Экстракт: '30 мг' },
            { Состав: 'Цианокобаламин (витамин В12)', Экстракт: '7,5 мкг' },
            { Состав: 'Фолиевая кислота (витамин В9)', Экстракт: ' 750 мкг' },

          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi",
            "Ovqatlanishdan 20 minut so'ng",
            "Kattalar : 10ml siropdan kuniga 1-2 maxal",
            "12-18 yoshgacha bo'lgan bolalar : 10ml siropdan kuniga 1 maxal",
            "6-12 yoshgacha bo'lgan bolalar : 5-10ml siropdan kuniga 1 maxal",
            "6 yoshgacha bo'lgan bolalar : davolovchi mutahassis tomonidan belgilanadi",
            "Davolash kursi : 1 - 2 oy. Mutahassis maslahati tavsiya etiladi"
          ],

          contraindications: [
            "Preparat komponentlariga shahsiy o'zlashtira olmaslik"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, через 20 минут после еды, запивая стаканом воды',
            'Взрослым: по 10 мл сиропа 1 - 2 раза в день',
            'Детям 12 - 18 лет: по 10 мл сиропа 1 раз в день',
            'Детям 6 - 12 лет: по 5 - 10 мл сиропа 1 раз в день',
            'Детям до 6 лет: дозировка определяется лечащим врачом',
            'Длительность курса: 1 - 2 месяца. Рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'индивидуальная непереносимость к компонентам препарата'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    // Product ABLAST
    // ID : 20
    {
      id: 20,
      router_name: 'Ablast',
      name: ['Ablast', 'Авицерон', 'Ablast'],
      cost: '',
      title: ['', '', ''],
      image: '/products/1280x1010/Ablast_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Kapsula', 'Капсула', 'Capsule'],
      type_index: 0,
      view: [
        'Chiqarilish shakli: Kapsula, 60 dona',
        'Форма выпуска: Капсула, по 60 штук в упаковке',
        'Release form: Capsule, 60 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Ablast_1280x1010.jpg'
        }
      ],
      subtitle: ['', '', ''],
      // Kereligi
      indication: [
        {
          id: 0,
          data: ['']
        },
        {
          id: 1,
          data: ['']
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: []
        },
        //Russian language
        {
          id: 1,
          items: []
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [],

          contraindications: []
        },
        {
          //Russian language
          id: 1,
          data: [],

          contraindications: []
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    //#endregion

    //#region 6)

    // Product RCV
    // ID : 21
    {
      id: 21,
      router_name: 'Rcv',
      name: ['RCV', 'RCV', 'RCV'],
      cost: '',
      title: [
        '',
        'Oбусловлены комплексным воздействием входящих в состав препарата компонентов',
        ''
      ],
      image: '/products/1280x1010/RCV_1280x1010.jpg',
      link: '/singleproduct',
      category: 'energy',
      type: ['Kapsula', 'Капсула', 'Capsule'],
      type_index: 0,
      view: [
        'Chiqarilish shakli: Kapsula, 60 dona',
        'Форма выпуска: Капсула, по 60 штук в упаковке',
        'Release form: Capsule, 60 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/RCV_1280x1010.jpg'
        }
      ],
      subtitle: [
        "RSV fagotsitoz shuningdek immunitetning hujayra qismini kuchli faollashtiruvchi hidoblanadi. Ta'sir mexanizmi uning makrofaglar yuzasidagi o'ziga xos retseptorlari uchun aniq selektivligi bilan izohlanadi (Dektin-1, Komplement 3, Laktosilseramid va boshqalar),natijada makrofaglar faollashadi, bu esa tananing immunitetini himoya qilishga qaratilgan trigger mexanizmlarini amalga oshirishga olib keladi. Limfotsitlarning sitotoksik ta'sirini rag'batlantiradi, makrofaglarning fagotsitik funktsiyasini faollashtiradi, bu interleykinlar, gamma-interferon ishlab chiqarishni ko'payishiga olib keladi. O'simta nekrozi omilini ishlab chiqarishni rag'batlantiradi, bu esa, o'z navbatida, monositik immunitet tizimini faollashtiradi.\n RSV mudofaa mexanizmlarini kuchaytiradi va tananing turli etiologiyali kasalliklariga chidamliligini oshirishga yordam beradi, ishtahani yaxshilaydi, samaradorlik va aqliy faollikni oshiradi",
        '«РСВ» является мощным активатором клеточного звена иммунитета, а также фагоцитоза. Механизм действия объясняется его выраженной селективностью в отношении специфических рецепторов на поверхности макрофагов (Dectin-1, Complement 3, Lactosylceramide и др.) в результате чего происходит активация макрофагов, что приводит к реализации триггерных механизмов целого ряда процессов, направленных на иммунную защиту организма. Стимулирует цитотоксические эффекты лимфоцитов, активирует фагоцитарную функцию макрофагов, приводящий к усилению выработки интерлейкинов, гамма интерферона. Стимулирует продукцию фактора некроза опухоли, который, в свою очередь, активирует моноцитарную систему иммунитета. «РСВ» повышает защитные механизмы и способствует повышению сопротивляемости организма к заболеваниям «РСВ» повышает защитные механизмы и способствует повышению сопротивляемости организма к заболеваниям',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Ginekologik kasalliklarda samarali : Endometrioz, Endometriyal poliplar, Bachadon miomasi, Bachadon qo'shimchalarining yallig'lanishi va boshqa surunkali jinsiy infektsiyalar",
            "Jinsiy yo'l bilan yuqadigan kasalliklar (STD) uchun samarali: Gonoreya, Xlamidiya, Trixomoniaz, Ureaplazmo, Vaginal kandidoz va boshqalar",
            "Immunitet tizimiga - gomeostazni saqlashda yordam beradi",
            "Dori terapiyasining ta'sirini kuchaytiradi va undagi nojo'ya ta'sirlarni kamaytiradi",
            "Terining shifo jarayonini tezlashtiradi, turli jarohatlar bilan, allergik reaktsiyalarni yengillashtiradi",
            "",
            "Onkologiyada qo'llanilishi :",
            "- Saraton kasalliklarida kimyoterapiya, radiatsiya terapiyasi davrida qo'shimcha dori terapiyasi sifatida qabul qilinadi",
            "- Malign o'smalari bo'lgan bemorlarda radikal jarrohlik davolashdan keyin reabilitatsiya terapiyasi davrida foydalaniladi",
            "*D. R. SABIROV, “Yomon oʻsmalarni davolashda optimallashtirilgan immunokorrektiv yondashuv”, 2022 y.",
            "",
            "Murakkab Terapiyada :",
            "-Turli xil etiologiyalarning urogenital infektsiyalari (xlamidiya, ureaplazmoz, mikoplazmoz, gonokokk infektsiyasi, inson papillomavirusi va boshqalar)",
            "TORCH infektsiyalari - sitomegalovirus infektsiyasi, toksoplazmoz, qizilcha, gerpes va boshqalar",
            "Surunkali virusli gepatit (B, C), OIV infektsiyasi",
            "Dori yoki radiatsiya terapiyasini olishdan oldin immunokorrektsiya",
          ]
        },
        {
          id: 1,
          data: [
            'Факторы действия:',
            '- эффективен при гинекологических заболеваниях: эндометриоз,   полипы эндометрия, фибромиома матки, воспаление придатков матки и других хронических половых инфекциях',
            'Эффективен при заболеваниях передающиеся поло-вым путем (ЗППП): гонорея, хламидиоз, трихомониаз, уреаплазмоз, вагинальный кандидоз и др',
            'Помогает иммунной системе поддерживать гомеостаз',
            'Усиливает действие лекарственной терапии и уменьшает побочные эффекты от ее применения\n',
            '',
            'Применение в онкологии*:',
            '- Принимается как сопроводительная медикаментозная  терапия в период проведения Химиотерапии, Радиотерапии у онкологических больных ',
            '- В период восстановительной терапии после радикального оперативного лечения у больных со злокачественными новообразованиями',
            '*Д. Р. САБИРОВ, «Оптимизированный иммунокоррегирующий подход к терапии злокачественных новообразований», 2022',
            '',
            'Показания к применению (в комплексной терапии):',
            'Урогенитальные инфекции различной этиологии (хламидиоз, уреаплазмоз, микоплазмоз, гонококковая инфекция, вирус папилломы человека и другие)',
            'TORCH инфекции - цитомегаловирусная инфекция, токсоплазмоз, краснуха, герпес и другие',
            'Хронические вирусные гепатиты (B, С), ВИЧ - инфекция',
            'Иммунокоррекция перед получением лекарственной или лучевой терапии'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: "Limfoid to'qimalarning lizatasi", Miqdori: "6 mg" },
            { Tarkibi: "Selen", Miqdori: "50 mkg" },
            { Tarkibi: "Beta-1,3/1,6-glyukan", Miqdori: "100 mg" },
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Лизат лимфоидной ткани', Экстракт: '6 мг' },
            { Состав: 'Селен', Экстракт: '50 мкг' },
            { Состав: 'Бета-1,3/1,6-глюкан', Экстракт: '100 мг' }
          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Suv bilan ichga qabul qilish orqali qo'llaniladi",
            "Ovqatlanish vaqtida yoki ovqatlanishdan so'ng",
            "Kattalar : 1-2 kapsuladan kuniga 2 maxal",
            "13~18 yoshgacha bolalar : 1 kapsuladan kuniga 2 maxal",
            "5~12 yoshgacha bolala : 1 kapsuladan kuniga 1 maxal",
            "Qabul qilish muddati: 30 - 60 kun",
            "Dori terapiyasi yoki radiatsiya terapiyasidan oldingi tayyorgarlik terapiyasida : 20 dan 30 kungacha",
            "Qayta kurs : 7-10 kunlik tanaffusdan so'ng mumkin",
            "Mutahassis maslahati tavsiya etiladi",
          ],

          contraindications: [
            "Allergik reaktsiyalar va bronxial astma kasalliklarining o'tkir davrida foydalanish tavsiya etilmaydi",
            "Miyokardning jiddiy shikastlanishida tavsiya etilmaydi",
            "O'sma kasalliklarining terminal bosqichida ham",
            "Preparat komponentlariga shahsiy o'zlashtiraolmaslik",
            "Yomon ta'siri:  kamdan-kam hollarda allergik reaktsiyalar paydo bo'lishi mumkin"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, во время или после еды, запивая стаканом воды',
            'Взрослым: по 1 - 2 капсуле 2 раза в день',
            'Детям 13 - 18 лет: по 1 капсуле 2 раз в день',
            'Детям 5 - 12 лет: по 1 капсуле 1 раз в день',
            'Длительность приёма: 30 - 60 дней',
            'При подготовительной терапии перед лекарственной или лучевой терапией – от 20 до 30 дней',
            'Повторный курс возможен с перерывом в 7 - 10 дней. Рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'Не рекомендуется применять в остром периоде заболевания, при острых аллергических реакциях и бронхиальной астме',
            'При тяжелых поражениях миокарда',
            'Терминальная стадия опухолевых заболеваний',
            'Индивидуальная непереносимость к компонентам препарата',
            'Побочные эффекты: в редких случаях могут появиться аллергические реакции'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    // Product TIROSNAB
    // ID : 22
    {
      id: 22,
      router_name: 'Tirosnab',
      name: ['Tirosnab', 'Тироснаб', 'Tirosnab'],
      cost: '',
      title: [
        "Yod tanqisligi bilan bog'liq kasalliklarning oldini olish va davolash uchun",
        'Для профилактики и лечения заболеваний, связанных с дефицитом йода',
        ''
      ],
      image: '/products/1280x1010/Tirosnab_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: '',
      type: ['Tabletka', 'Таблетка', 'Tablet'],
      type_index: 1,
      view: [
        'Chiqarilish shakli: tabletka, 60 dona',
        'Форма выпуска: таблетки, по 60 штук в упаковке',
        'Release form: tablets, 60 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Tirosnab_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        "TIROSNAB - qalqonsimon bez kasalliklarining oldini olish va davolash uchun yod preparati. Yod qalqonsimon bez gormonlarining (qalqonsimon gormonlar) bir qismi bo'lib, ko'plab hayotiy muhim funktsiyalarni bajaradi, shu jumladan organizmdagi oqsillar, yog'lar, uglevodlar va energiya almashinuvini, asab tizimi, yurak-qon tomir tizimi, jinsiy va sut bezlari faoliyatini tartibga soladi hamda bolaning o'sishi va rivojlanishi ro'l o'ynaydi. TIROSNAB organizmdagi yod tanqisligini to'ldiradi, yod tanqisligi kasalliklari rivojlanishining oldini oladi, qalqonsimon bez faoliyatini normallashtirishga yordam beradi, bu bolalar, o'smirlar, shuningdek, homiladorlik va laktatsiya davrida muhim ahamiyatga ega.",
        '«ТИРОСНАБ» является препаратом йода для профилактики и лечения заболеваний щитовидной железы. Йод входит в состав гормонов щитовидной железы (тиреоидные гормоны), которые выполняют множество жизненно важных функций, в т. ч. регулируют обмен белков, жиров, углеводов и энергии в организме, деятельность нервной системы, сердечно-сосудистой системы, половых и молочных желез, а также рост и развитие ребенка. «ТИРОСНАБ» восполняет дефицит йода в организме, препятствуя развитию йододефицитных заболеваний, способствует нормализации функции щитовидной железы, что важно для детей, подростков, а также при беременности и в период лактации.',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Endemik buqoq oldini olish (bolalar, o'smirlar, homiladorlik va emizish davrida)",
            "Yod tanqisligi buqoqni terapevtik davolashdan keyin relapslarning oldini olish",
            "Yod tanqisligidan kelib chiqqan diffuz eutireoid buqoqni davolashda"
          ]
        },
        {
          id: 1,
          data: [
            'Показания к применению (в комплексной терапии):',
            '- Профилактика эндемического зоба (у детей, подростков, при беременности и в период грудного вскармливания)',
            '- Профилактика рецидивов после терапевтического лечения йоддефицитного зоба',
            '- Лечение диффузного эутиреоидного зоба, вызванного дефицитом йода'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [

            { Tarkibi: "Kaliy Yod", Miqdori: '200 mkg' },

          ]
        },
        //Russian language
        {
          id: 1,
          items: [{ Состав: 'Калия йодид', Экстракт: '200 мкг' }]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi,  suv bilan",
            "Ovqatlanishdan 20 daqiqa oldin",
            "Kattalar va 12 yoshdan katta bolalar : 0,5 - 1 tabletkadan kuniga 1 maxal",
            "12 yoshgacha bo'lgan bolalar : 0,5 tabletkadan kuniga 1 maxal",
            "Xomiladorlik va laktatsiya davri : 1 tabletkadan kuniga 1 maxal",
            "",
            "Qabul qilish muddati: profilaktik qabul qilish bir necha yil davomida amalga oshiriladi,",
            "agar ko'rsatmalar mavjud bo'lsa - hayot davomida qabul qilinadi",
            "Yangi tug'ilgan chaqaloqlarda buqoq davolash uchun o'rtacha 2 dan 4 haftagacha",
            "Mutaxassis maslahati tavsiya etiladi"


          ],

          contraindications: [
            "Gipotireoz, preparat komponentlariga shahsiz o'zlashtira olmaslik"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, за 20 минут до еды, запивая стаканом воды',
            'Взрослым и детям с 12 лет: по 0,5 - 1 таблетке 1 раз в день',
            'Детям до 12 лет: по 0,5 таблетке 1 раз в день',
            'Беременность и период лактации: по 1 таблетке 1 раз в день',
            'Длительность приёма: профилактический прием проводится в течении нескольких лет, а при наличии показаний -',
            'пожизненно. Для лечения зоба у новорожденных, в среднем от 2 до 4 недель; Рекомендуется согласовать со специалистом'
          ],

          contraindications: [
            'гипертиреоз, индивидуальная непереносимость к компонентам препарата'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },

    // Product TIROSNAB B9
    // ID : 23
    {
      id: 23,
      router_name: 'Tirosnab_B9',
      name: ['Tirosnab B9', 'Тироснаб Б9', 'Tirosnab B9'],
      cost: '',
      title: [
        "Yod va foliy kislotasi yetishmovchiligi bilan bog'liq kasalliklarning oldini olish va davolash uchun",
        'Для профилактики и лечения заболеваний, связанных с дефицитом йода и фолиевой кислоты',
        ''
      ],
      image: '/products/1280x1010/Tirosnab_B9_Gray_1280x1010.jpg',
      link: '/singleproduct',
      category: 'energy',
      view: [
        'Chiqarilish shakli: tabletka, 50 dona',
        'Форма выпуска: таблетки, по 50 штук в упаковке',
        'Release form: tablets, 50 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Tirosnab_B9_Gray_1280x1010.jpg'
        }
      ],
      subtitle: [
        "TIROSNAB +B9 tarkibidagi foliy kislotasi platsentaning normal kamolotiga va ishlashiga yordam beradi, shuningdek, megaloblastlarning normal pishib yetishi va normoblastlarning shakllanishi uchun zarurdir. JSST (Jahon sog'liqni saqlash tashkiloti) homiladorlik davrida mutlaqo barcha ayollar tomonidan qabul qilinishini tavsiya qiladi. TIROSNAB +B9 yod va foliy kislotasi etishmovchiligini qoplaydi, foliy kislotasi va yod yetishmovchiligi bilan bog'liq kasalliklarning rivojlanishiga to'sqinlik qiladi. Yod qalqonsimon bezning normal ishlashi uchun zarur bo'lgan muhim mikroelement bo'lib, qalqonsimon bezning gormonlari (qalqonsimon gormonlar) tarkibiga kiradi, ular ko'plab hayotiy muhim funktsiyalarni bajaradi, shu jumladan organizmdagi oqsillar, yog'lar, uglevodlar va energiya almashinuv faoliyatini hamda asab tizimi, yurak-qon tomir tizimi, reproduktiv va sut bezlari faoliyatlarini tartibga soladi",
        'Фолиевая кислота в составе «ТИРОСНАБ +В9» способствует нормальному созреванию и функционированию плаценты, также необходима для нормального созревания мегалобластов и образования нормобластов. ВОЗ рекомендуют её принимать при беременности абсолютно всем женщинам. «ТИРОСНАБ +В9» восполняет дефицит йода и фолиевой кислоты, препятствуя развитию заболеваний связанных дефицитом фолиевой кислоты и йода. Йод, является жизненно важным микроэлементом, необходимым для нормальной работы щитовидной железы,  входит в состав гормонов щитовидной железы (тиреоидные гормоны), которые выполняют множество жизненно важных функций, в т. ч. регулируют обмен белков, жиров, углеводов и энергии в организме, деятельность нервной системы, сердечно-сосудистой системы, половых и молочных желез.',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Rejalashtirish bosqichida va homiladorlik davrida - onada xavf omillari mavjud bo'lganda homilada nerv naychalari nuqsonlari rivojlanishining oldini olishda",
            "Anemiya va leykopeniyaning kombinatsiyalangan terapiyasining bir qismi sifatida",
            "Endemik buqoqning oldini olish (homiladorlik va emizish davrida)",
            "Yod tanqisligi buqoqini oldini olish va davolashda"
          ]
        },
        {
          id: 1,
          data: [
            'Показания к применению (в комплексной терапии):',
            '- На этапе планирования и во время беременности для профилактики развития дефектов нервной трубки у плода при наличии факторов риска у матери',
            '- В составе комбинированной терапии анемии и лейкопении',
            '- Профилактика эндемического зоба (при беременности и в период грудного вскармливания)',
            '- Профилактика и лечение йоддефицитного зоба'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: "Foliy kislotasi (Vitamin B9)", Miqdori: '400 mkg' },
            { Tarkibi: "Kaliy yod", Miqdori: '200 mkg' },
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'Фолиевая кислота (витамин В9)', Экстракт: '400 мкг' },
            { Состав: 'Калия йодид', Экстракт: '200 мкг' }
          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilish orqali qo'llaniladi,  suv bilan",
            "Ovqatlanishdan 20 daqiqa oldin",
            "Kattalar va 12 yoshdan katta bolalar : 0,5 - 1 tabletkadan kuniga 1 maxal",
            "12 yoshgacha bo'lgan bolalar : 0,5 tabletkadan kuniga 1 maxal",
            "Xomiladorlik va laktatsiya davri : 1 tabletkadan kuniga 1 maxal",
            "Qabul qilish muddati: davolovchi shifokor tomonidan individual ravishda belgilanadi"
          ],

          contraindications: [
            "Gipotireoz, preparat komponentlariga shahsiz o'zlashtira olmaslik"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, за 20 минут до еды, запивая стаканом воды',
            'Взрослым и детям с 12 лет: по 0,5 - 1 таблетке 1 раз в день',
            'Детям до 12 лет: по 0,5 таблетке 1 раз в день',
            'Беременность и период лактации: по 1 таблетке 1 раз в день',
            'Длительность приёма: устанавливается индивидуально лечащим врачом',
            ''
          ],

          contraindications: [
            'гипертиреоз, индивидуальная непереносимость к компонентам препарата'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    },


    // Product FORTEBON
    // ID : 24
    {
      id: 24,
      router_name: 'Fortebon',
      name: ['Fortebon', 'Фортебон', 'Фортебон'],
      cost: '',
      title: [
        "Organizmda kalsiy va vitvmin D3 tanqisligini taʼminlaydi.",
        'Восполняет дефицит кальция и витамина Д3 в организме.',
        ''
      ],
      image: '/products/1280x1010/Fortebon_2.jpg',
      link: '/singleproduct',
      category: 'energy',
      view: [
        'Chiqarilish shakli: Chaynaladigan tabletka, 60 dona',
        'Форма выпуска: жевательные таблетки. По 60 жевательных таблеток во флаконе.',
        'Release form: tablets, 50 pieces per pack'
      ],
      children_image: [
        {
          id: 1,
          image: '/products/1280x1010/Fortebon_1.jpg'
        }
      ],
      subtitle: [
        "Organizmda kalsiy va vitvmin D3 tanqisligini toʻldirib, suyak toʻqimasi rezobsiyasini (soʻrilishini) kamaytiradi va zichligini oshiradi, tishlar minerallanishini taʼminlaydi. Kalsiy nerv oʻtkazuvchanligi boshqarilishida, mushaklar qisqarishida, gormonlar ishlab chiqarilishida ishtirok etadi, shuningdek, qon ivish sistemasi komponenti hisoblanadi.",
        '«ФОРТЕБОН» восполняет дефицит кальция, витамина Д3, в организме, препятствует развитию остеопороза, рахита. Кальций участвует в формировании костной ткани, минерализации зубов, регуляции проводимости нервных импульсов и мышечных сокращений, является компонентом системы свертывания крови. Витамин D3 усиливает всасывание кальция в кишечнике и его связывание в костной ткани, участвует в регуляции обмена кальция, фосфора в организме.',
        ''
      ],
      // Kereligi
      indication: [
        {
          id: 0,
          data: [
            "Osteoporoz",
            "Suyak sinishi",
            "Raxit",
            "Oyoqlar qiyshiqligi, o'sish to'xtashida, past bo'ylikda",
            "Tishlar kech chiqishida",
            "Stress, jizzakilik",
            "Kamsochlik va tirnoqlardagi oq dog'larda Kalsiy, fosfor, vitamin D3 tanqisligini oldini olish va davolashda"

          ]
        },
        {
          id: 1,
          data: [
            'Профилактика и лечение дефицита кальция, витамина D3',
            'Для профилактики и в комплексном лечении остеопороза, рахита'
          ]
        },
        {
          id: 2,
          data: ['']
        }
      ],
      // Tarkibi
      composition: [
        //Uzbek language
        {
          id: 0,
          items: [
            { Tarkibi: "Kalsiy karbonat", Miqdori: '800 mg' },
            { Tarkibi: "Kolekalsiyferol (Vitamin D3)", Miqdori: '200 XB' },
          ]
        },
        //Russian language
        {
          id: 1,
          items: [
            { Состав: 'кальция карбонат', Экстракт: '800 мг' },
            { Состав: 'колекальциферол (витамин D3)', Экстракт: '200 МЕ' }
          ]
        },
        //English language
        {
          id: 2,
          items: []
        }
      ],
      // Ishlatishi
      useway: [
        {
          //Uzbek language
          id: 0,
          data: [
            "Ichga qabul qilinadi, ovqatlanish vaqtida tabletkani chaynash yoki shimish lozim",
            "Kattalar va 12 yoshdan katta bolalar : 1 tabletkadan kuniga 2 maxal",
            "3 yoshdan 12 yoshgacha bo'lgan bolalar : 0,5 - 1 tabletkadan kuniga 1 maxal",
            "Qabul qilish muddati: 1-2 oy. Davolovchi shifokor tomonidan individual ravishda belgilanadi",
          ],

          contraindications: [
            "Preparat komponentlariga shaxsiy o'zlashtira olmaslik, giperkalsimiya, og'ir buyrak kasalliklari"
          ]
        },
        {
          //Russian language
          id: 1,
          data: [
            'Приём внутрь, во время еды, таблетки следует разжёвывать или рассасывать',
            'Взрослым и детям старше 12 лет: по 1 таблетке 2 раза в день',
            'Детям с 3 лет до 12 лет: по 0,5 - 1 таблетке 1 раз в день',
            'Длительность приёма: 1 - 2 месяца. При необходимости возможен повторный курс с перерывом 30 дней. Рекомендуется согласовать со специалистом',
            'Побочные эффекты: в редких случаях: аллергические реакции, слабовыраженные явления диспепсии, (метеоризм, тошнота, запор или диарея)'
          ],

          contraindications: [
            'индивидуальная непереносимость к компонентам препарата, гиперкальциемия, тяжелые заболевания почек'
          ]
        },
        {
          //English language
          id: 2,
          data: [],

          contraindications: []
        }
      ]
    }

    //#endregion
  ]
}
