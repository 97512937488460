<template>
  <div>
    <!-- MAIN -->
    <section>
      <div>
        <b-row class="justify-center mr-0 ml-0 main">
          <!-- 3 TA DORI RASM -->
          <b-col lg="4" md="4" align-self="top" class="imageDori3">
            <div class="boxImage3 " data-aos="zoom-in-down">
              <div class="gImage rotateimg30">
                <img src="@/assets/design/3tadori.png" alt="Feature Image" />
              </div>
            </div>
          </b-col>
          <!-- YOZUV -->
          <b-col
            lg="4"
            md="4"
            sm="12"
            xs="12"
            align-self="center"
            class="mainText justify-center text-center"
          >
            <b-row class="mr-0 ml-0">
              <b-col sm="12" class="justify-center text-center">
                <h3 class="font-top">{{ $t('page-main.title') }}</h3>
                <h2 class="font-title">BioMed Industry</h2>
              </b-col>
            </b-row>

            <div class="font-subtitle">
              {{ $t('page-main.subtitle') }}
            </div>
          </b-col>
          <!-- CHASHKA DORI RASM -->
          <b-col lg="4" md="4" sm="12" align-self="end" class="text-right ">
            <div class="boxImage" data-aos="zoom-in-up">
              <div class="gImage rotateimg180">
                <img src="@/assets/design/dori_chashkada.jpg" />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </section>

    <!-- TOGRI TANLOV SECTION -->
    <section class="module">
      <b-row align-h="center" class="mr-0 ml-0 ">
        <b-col lg="6" md="12" sm="12" class="">
          <div class="gallery " data-aos="fade-right">
            <b-img
              fluid
              left
              :src="require('@/assets/design/ishong.jpeg')"
              alt="Left image"
            ></b-img>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="" align-self="center">
          <b-row class="mr-0 ml-0">
            <b-col sm="12" md="8" offset-md="1" class="pb-4">
              <div class="mobile_info_title">
                <span class="info_title">{{
                  $t('page-main.info-tanlov-title')
                }}</span>
              </div>
            </b-col>
            <b-col lg="8" md="8" sm="12" offset-md="1">
              <div class="pt-20">
                <div class="info_subtitle">
                  {{ $t('page-main.info-tanlov-subtitle') }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <!-- ISHONCH SECTION -->
    <section class="module">
      <b-row align-h="center" class="mr-0 ml-0">
        <b-col lg="6" md="12" sm="12" order-lg="1" order-sm="0">
          <div class="gallery" data-aos="fade-down">
            <b-img
              fluid
              left
              :src="require('@/assets/design/protection.jpeg')"
              alt="Left image"
            ></b-img>
          </div>
        </b-col>

        <b-col
          lg="6"
          md="12"
          sm="12"
          align-self="center"
          order-lg="0"
          order-sm="1"
        >
          <b-row>
            <b-col sm="12" md="8" offset-md="1" class="pb-4">
              <div class="mobile_info_title">
                <span class="info_title">{{
                  $t('page-main.info-ishonch-title')
                }}</span>
              </div>
            </b-col>
            <b-col lg="8" md="8" sm="12" offset-md="1">
              <div class="pt-20">
                <div class="info_subtitle">
                  {{ $t('page-main.info-ishonch-subtitle') }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <!-- SIFAT SECTION -->
    <section class="module">
      <b-row align-h="center" class="mr-0 ml-0">
        <b-col lg="6" md="12" sm="12">
          <div
            class="gallery d-flex justify-center align-center"
            data-aos="fade-up"
          >
            <b-img
              fluid
              left
              :src="require('@/assets/design/bolala.jpeg')"
              alt="Left image"
            ></b-img>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="" align-self="center">
          <b-row class="mr-0 ml-0">
            <b-col sm="12" md="8" offset-md="1" class="pb-4">
              <div class="mobile_info_title">
                <span class="info_title">{{
                  $t('page-main.info-sifat-title')
                }}</span>
              </div>
            </b-col>
            <b-col lg="8" md="8" sm="12" offset-md="1">
              <div class="pt-20">
                <div class="info_subtitle">
                  {{ $t('page-main.info-sifat-subtitle') }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <!-- <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="container-general">
            <div class="gallery-wrap wrap-effect-bek">
              <div
                class="item"
                :style="this.useSytleArray[0] ? useClass : ''"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div class="itemChild" onclick="window.location='products';">
                  <h1>
                    <a :href="`/${$i18n.locale}/products`">
                      {{ $t("page-title.product.title") }}
                    </a>
                  </h1>
                </div>
              </div>

              <div
                class="item"
                :style="useSytleArray[1] ? useClass : ''"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div class="itemChild" onclick="window.location='about';">
                  <h1>
                    <a :href="`/${$i18n.locale}/about`">
                      {{ $t("page-title.about.title") }}
                    </a>
                  </h1>
                </div>
              </div>

              <div
                class="item"
                :style="this.useSytleArray[2] ? useClass : ''"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div class="itemChild" onclick="window.location='shop';">
                  <h1>
                    <a :href="`/${$i18n.locale}/shop`">
                      {{ $t("page-title.shop.title") }}
                    </a>
                  </h1>
                </div>
              </div>

              <div
                class="item"
                :style="this.useSytleArray[3] ? useClass : ''"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div class="itemChild" onclick="window.location='contact';">
                  <h1>
                    <a :href="`/${$i18n.locale}/contact`">
                      {{ $t("page-title.contact.title") }}
                    </a>
                  </h1>
                </div>
              </div>

              <div
                class="item"
                :style="this.useSytleArray[4] ? useClass : ''"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div class="itemChild" onclick="window.location='faq';">
                  <h1>
                    <a :href="`/${$i18n.locale}/faq`">
                      {{ $t("page-title.faq.title") }}
                    </a>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Language from '../Titan/t-language-switcher.vue'

export default {
  data () {
    return {
      imge_ishonch: '@/assets/design/ishong.jpeg',
      urlProduct: 'products',
      isEnable: true,

      useClass: 'itemOver',
      useStyle: false,
      useSytleArray: [false, false, false, false, false]
    }
  },
  components: {
    // Language
  },
  methods: {
    goUrl: function (goLink) {
      window.location.href = '`/${$i18n.locale}/ `' + goLink
    },
    doMouseOver (id) {
      console.log(id)
      this.useStyle = true
      this.useSytleArray[id] = true
      this.useClass = 'flex : 1.5;'
    },
    doMouseLeave (id) {
      this.useStyle = false
      this.useSytleArray[id] = false
      this.useClass = 'flex : 1;'
    }
  },
  computed: {
    ...mapState('cart', ['cart'])
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.module {
  position: relative;
  padding-top: 30px;
  padding-bottom: 100px;
  margin-left: 0;
  margin-right: 0;
}

.main {
  height: 100vh;
  width: 100%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.mainTitle {
  padding-bottom: 5rem;
  padding-top: 10rem;

}

.font-top {
  font-family: 'Cormorant';
  font-style: italic;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  color: rgb(75, 75, 75);
}

.font-title {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  // letter-spacing: 35px;
  line-height: 70px;
  letter-spacing: 8px;
  font-size: 53px;
  font-weight: 500;
}

.font-subtitle {
  // font-family: "Roboto", sans-serif;
  text-align: center;
  line-height: 32px;
  letter-spacing: 0px;
  font-weight: 200;
  font-size: 21px;
}
.mainText {
  padding-bottom: 50px;
  z-index: 10;
}
.amount {
  font-size: 28px;
  color: rgba(68, 22, 22, 0.774);
}
.product-title {
  margin: 0 0 20px;
  color: #111;
}

.boxImage {
  // position: fixed;
  display: flex;
  // overflow: hidden;
  transition: all 0.5s ease;
  z-index: -1;
  padding-bottom: 50px;
  padding-left: 100px;

  .gImage img {
    height: 250px;
    overflow: hidden;
  }
}

.boxImage3 {
  // position: fixed;
  padding-top: 20px;
  display: flex;
  // overflow: hidden;
  // right: 0;
  left: 0;
  // bottom: 40%;
  transition: all 0.5s ease;
  z-index: -1;

  .gImage img {
    height: 300px;
  }
}

.rotateimg180 {
  animation: animation_rotateimg 10s infinite;
}

.rotateimg30 {
  animation: animation_rotateimg_30 10s infinite;
}

.info-section {
  // display: flex;
  align-content: center;
  align-items: center;
}

.info_title {
  font-size: 30px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.info_subtitle {
  font-size: 15px;
  // font-family: 'Roboto', sans-serif;
  color: #6b6b6b;
  text-transform: none;
}

// .gallery {
//   // justify-content: center;
// }
// animation function
@keyframes animation_rotateimg {
  0% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -o-animation: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes animation_rotateimg_30 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -o-animation: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    -o-animation: rotate(30deg);
    transform: rotate(30deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -o-animation: rotate(0deg);
    transform: rotate(0deg);
  }
}

@media (max-width: 990px) {
  .boxImage {
    transition: all 0.5s ease;
    align-content: center;
    justify-content: center;
    padding-left: 0px;
    z-index: -1;
    // overflow: hidden;
  }

  .imageDori3 {
    visibility: hidden;
    margin-bottom: -30%;
  }
  .gallery {
    // order: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    // d-flex justify-center align-center
  }

  .mobile_info_title {
    margin-top: 20px;
  }
  .info_title {
    padding-top: 10px;
  }
}
</style>
